import DatePicker from "./DatePicker";
import SingleDatePicker from "./SingleDatePicker";

import React, { useEffect, useRef } from "react";

export default function DateDropDown({
  toggleDateClose,
  onDateChange,
  clinic,
}) {
  const menuRef = useRef();
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (menuRef.current.contains(e.target)) {
      // Inside the event (handleClick) this.node.contains(e.target) will return true if whatever you are clicking is inside the menu ref.
      console.log("ss");
      return;
    }
    toggleDateClose();
    console.log("ddd");
  };

  return (
    <div
      ref={menuRef}
      className={`menu-dropdown menu-dropdown__form  menu-dropdown__open ${
        clinic ? "menu-dropdown__form--dateSingle" : "menu-dropdown__form--date"
      }`}
    >
      {clinic ? (
        <SingleDatePicker onDateChange={onDateChange} />
      ) : (
        <DatePicker onDateChange={onDateChange} />
      )}
    </div>
  );
}
