import React from "react";
import routes from "../routes";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "../components/Hoc/auth/ProtectedRoute";
import LoggedInRoute from "../components/Hoc/auth/LoggedInRoute";
import WithGeneralTheme from "../components/Hoc/themes/WithGeneralTheme";
import { connect } from "react-redux";
import { setGlobalProps } from "../actions";

const switchRoutes = (
  <Switch>
    {routes.GeneralRoute.map((prop, key) => {
      if (!prop.protected && !prop.logged) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            
          />
        );
      } else if (prop.protected) {
        return (
          <ProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else if (prop.logged) {
        return (
          <LoggedInRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/general" to="/general/notFound" />
  </Switch>
);
class General extends React.Component {
  componentDidMount() {
    this.props.setGlobalProps({
      themeClass: "theme-general",
      themeColorClass: "theme-general-color",
    });
  }
  render() {
    return <div>{switchRoutes}</div>;
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setGlobalProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
