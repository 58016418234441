import React, { Component } from "react";
import {
  IoMdGrid,
  IoMdCard,
  IoIosArrowUp,
  IoIosArrowDown,
} from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import WithEventsTheme from "../../Hoc/themes/WithEventsTheme";
import CollapseComponent from "../../shared/Shop/CollapseComponent";
import MenuDropDown from "../../shared/MenuDropDown";
import LoadMoreCards from "../../shared/Shop/LoadMoreCards";
import { connect } from "react-redux";
import {
  getProductsToShop,
  getCategories2,
  clearShopProducts,
} from "../../../actions";
import EventsForm from "../EventsForm";
import CardEvents from "./CardEvents";

const data = {
  size: 3,
  products: [
    {
      photos: [
        "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
        "772020191",
      ],
      available: true,
      assets: [],
      bookings: [],
      deleted: false,
      _id: "5e89312d1898141504ada1d7",
      bookedTimes: 0,
      name: " الطيار العربي 911",
      description: "لبس زفة عليه معلم الجنبية من الهوية اليمنية",
      categoryName: "5e89306d1898141504ada1d1",
      designName: " الطيار العربي",
      price: 12000,
      quantity: 6,
      orboon: {
        _id: "5e89312d1898141504ada1d8",
        createdAt: "2020-04-05T01:15:25.866Z",
        percentage: 25,
        priceToPay: 3000,
      },
      createdAt: "2020-04-05T01:15:25.866Z",
      company: {
        phones: [771111201, 772020191],
        _id: "5e892f4158a9f22e28fdac5d",
        companyName: "مدينة صنعاء- ملبوسات عرسان ",
        address: {
          _id: "5e892f4158a9f22e28fdac5e",
          createdAt: "2020-04-05T01:07:13.489Z",
          city: "عدن",
          street: "شارع الكلية",
        },
        email: "madinatsanaa@gmail.com",
      },
      __v: 0,
    },
    {
      photos: [],
      available: true,
      assets: [],
      bookings: [
        {
          cancelled: false,
          _id: "5e89e1ac54c6c11f48559bf5",
          startAt: "2020-05-03T21:00:00.000Z",
          endAt: "2020-05-04T21:00:00.000Z",
          quantity: 1,
          totalPrice: 12000,
          transferNumber: 11111,
          eventAddress: {
            _id: "5e89e1ac54c6c11f48559bf6",
            createdAt: "2020-04-05T13:48:28.671Z",
            city: "صنعاء",
            street: "سواد حنش",
          },
          days: 2,
          product: "5e89de2254c6c11f48559be8",
          createdAt: "2020-04-05T13:48:28.671Z",
          paid: 5850,
          customer: "5e89478e1898141504ada1dc",
          __v: 0,
        },
        {
          cancelled: false,
          _id: "5e89e47c54c6c11f48559bfc",
          startAt: "2020-05-03T21:00:00.000Z",
          endAt: "2020-05-04T21:00:00.000Z",
          quantity: 5,
          totalPrice: 60000,
          transferNumber: 11111,
          eventAddress: {
            _id: "5e89e47c54c6c11f48559bfd",
            createdAt: "2020-04-05T14:00:28.391Z",
            city: "صنعاء",
            street: "سواد حنش",
          },
          days: 2,
          product: "5e89de2254c6c11f48559be8",
          createdAt: "2020-04-05T14:00:28.391Z",
          paid: 5850,
          customer: "5e89478e1898141504ada1dc",
          __v: 0,
        },
        {
          cancelled: false,
          _id: "5e89e539a1a1f53810f5e452",
          startAt: "2020-05-05T21:00:00.000Z",
          endAt: "2020-05-05T21:00:00.000Z",
          quantity: 1,
          totalPrice: 12000,
          transferNumber: 11111,
          eventAddress: {
            _id: "5e89e539a1a1f53810f5e453",
            createdAt: "2020-04-05T14:03:37.910Z",
            city: "صنعاء",
            street: "سواد حنش",
          },
          days: 1,
          product: "5e89de2254c6c11f48559be8",
          createdAt: "2020-04-05T14:03:37.911Z",
          paid: 3000,
          customer: "5e89478e1898141504ada1dc",
          __v: 0,
        },
      ],
      deleted: false,
      _id: "5e89de2254c6c11f48559be8",
      name: " الطيار العربي 811",
      description: "لبس زفة عليه معلم الجنبية من الهوية اليمنية",
      categoryName: "5e8930621898141504ada1cf",
      designName: " الطيار العربي",
      price: 12000,
      quantity: 6,
      orboon: {
        _id: "5e89de2254c6c11f48559be9",
        createdAt: "2020-04-05T13:33:22.465Z",
        percentage: 25,
        priceToPay: 3000,
      },
      createdAt: "2020-04-05T13:33:22.466Z",
      company: {
        phones: [771111201, 772020191],
        _id: "5e892f7158a9f22e28fdac60",
        companyName: "مدينة صنعاء- خيام أعراس",
        address: {
          _id: "5e892f7158a9f22e28fdac61",
          createdAt: "2020-04-05T01:08:01.152Z",
          city: "صنعاء",
          street: " القاهرة",
        },
        email: "madinatsanaa2@gmail.com",
      },
      __v: 3,
    },
    {
      photos: [],
      available: true,
      assets: [],
      bookings: [
        {
          cancelled: false,
          _id: "5e8a9d086b0a5322807933e3",
          startAt: "2020-04-05T21:00:00.000Z",
          endAt: "2020-04-05T21:00:00.000Z",
          quantity: 1,
          totalPrice: 12000,
          transferNumber: 11111,
          eventAddress: {
            _id: "5e8a9d086b0a5322807933e4",
            createdAt: "2020-04-06T03:07:52.897Z",
            city: "صنعاء",
            street: "سواد حنش",
          },
          days: 1,
          product: "5e89de4054c6c11f48559beb",
          createdAt: "2020-04-06T03:07:52.897Z",
          paid: 3000,
          customer: "5e89478e1898141504ada1dc",
          __v: 0,
        },
      ],
      deleted: false,
      _id: "5e89de4054c6c11f48559beb",
      name: " الطياxswdwcsxcacsacر العربي11",
      description: "لجنبية من الهوية اليمنية",
      categoryName: "5e8930621898141504ada1cf",
      designName: " الطيار العربي",
      price: 12000,
      quantity: 2,
      orboon: {
        _id: "5e89ee8846f4352c60327bb3",
        createdAt: "2020-04-05T14:43:20.754Z",
        percentage: 25,
        priceToPay: 3000,
      },
      createdAt: "2020-04-05T13:33:52.893Z",
      company: {
        phones: [771111201, 772020191],
        _id: "5e892f7158a9f22e28fdac60",
        companyName: "مدينة صنعاء- خيام أعراس",
        address: {
          _id: "5e892f7158a9f22e28fdac61",
          createdAt: "2020-04-05T01:08:01.152Z",
          city: "صنعاء",
          street: " القاهرة",
        },
        email: "madinatsanaa2@gmail.com",
      },
      __v: 1,
    },
  ],
};
class ShopIndex extends Component {
  constructor() {
    super();
    this.state = {
      sortList: {
        List: [
          { _id: 1, name: "حسب الرائج ", getBy: "bookedTimes", order: "desc" },
          { _id: 1, name: "حسب الأرخص", getBy: "price", order: "asc" },
          { _id: 1, name: "حسب الأغلى", getBy: "price", order: "desc" },
        ],
      },
      price: [
        { _id: 1, name: "الكل", array: [] },
        { _id: 2, name: "من 0 الى 5000", array: [0, 5000] },
        { _id: 3, name: "من 5000 الى 10000", array: [5000, 10000] },
        { _id: 4, name: "من 10000 الى 15000", array: [10000, 15000] },
        { _id: 5, name: "15000 فأكثر", array: [15000, 500000] },
      ],
      filterData: {
        filters: {
          categoryName: [],
          price: [],
          designName: [],
          company: [],
          quantity: [1],
        },
        date: { startAt: "", endAt: "" },
      },
      grid: "",
      limit: 3,
      skip: 0,
      order: "desc",
      getBy: "createdAt",
      checked: false,
      modalState: false,
    };
  }
  callProductsToShop = (isChanged, isLoadedMore) => {
    const { limit, skip, getBy, order, filterData } = { ...this.state };
    const skipMore = !isLoadedMore ? skip : skip + limit;
    this.props
      .getProductsToShop(
        "eventsProducts",
        isChanged ? 0 : skipMore,

        limit,
        getBy,
        order,

        filterData,
        isLoadedMore ? this.props.products.toShopProducts : []
      )
      .then((res) => {
        isChanged && this.setState({ skip: 0 });
        isLoadedMore && this.setState({ skip: skipMore });
      });
  };
  componentDidMount() {
    if (this.props.location.state) {
      this.setState(
        {
          filterData: { ...this.props.location.state.filterData },
        },
        () => {
          this.props.getCategories2("Events");
          this.callProductsToShop(false);
        }
      );
    } else {
      this.props.getCategories2("Events");
      this.callProductsToShop(false);
    }
  }
  componentWillUnmount() {
    this.props.clearShopProducts();
  }
  handleCheckboxChange = (event) => {
    this.setState({ checked: event.target.checked });
  };
  loadMoreAction = (e) => {
    e.preventDefault();
    this.callProductsToShop(false, true);
  };
  handleSort = (element) => {
    this.setState(
      {
        order: element.order,
        getBy: element.getBy,
      },
      () => this.callProductsToShop(true)
    );
  };
  handleFilters = (filters, type) => {
    console.log(filters);

    const newFilters = { ...this.state.filterData };

    if (type === "price") {
      //let priceValue = this.handlePrices(filters);
      let priceValue = [];
      priceValue[0] = filters.min * 1000;
      priceValue[1] = filters.max * 1000;
      newFilters.filters[type] = priceValue;
    } else {
      newFilters.filters[type] = filters;
    }
    this.setState(
      {
        filterData: newFilters,
      },
      () => this.callProductsToShop(true)
    );
  };
  onFormClick = (e, filterData) => {
    e.preventDefault();
    debugger
    this.setState(
      {
        filterData: { ...filterData },
      },
      () => {
        this.callProductsToShop(false);
      }
    );
  };
  renderFilterOptions = () => {
    return (
      <React.Fragment>
        <div className={`general-form theme-events-border`}>
          <h1 className={`general-form__title theme-events`}>استعلم</h1>

          <div className="general-form__body no-padding">
            <EventsForm
              sideForm={true}
              filterData={
                this.props.location.state && {
                  ...this.props.location.state.filterData,
                }
              }
              // filterData={this.state.filterData} we dont apply it cuz the form componenet did ount is already called
              onClick={this.onFormClick}
            />
          </div>
        </div>
        <CollapseComponent
          title="السعر"
          price={true}
          maxValue={900}
          minValue={2}
          initOpen={true}
          handleFilters={(filters) => {
            this.handleFilters(filters, "price");
          }}
        />
        <CollapseComponent
          title="التصنيفات"
          initOpen={true}
          checkedIndexes={this.state.filterData.filters.categoryName}
          list={this.props.typeCategories.List}
          handleFilters={(filters) => {
            this.handleFilters(filters, "categoryName");
          }}
        />
      </React.Fragment>
    );
  };

  toggleSort = () => this.setState({ sortOpen: !this.state.sortOpen });
  render() {
    console.log(this.state.filterData);
    const { sortOpen, sortList, data } = this.state;
    const arrowStyle = { margin: "0 5px 0 0", verticalAlign: "sub" };

    return (
      <div className="container">
        <div className="row   flex-wrap">
          <div className="col col-3">{this.renderFilterOptions()}</div>
          <div className="col col-9">
            <div className="shop-options row align-center flex-wrap justify-between">
              <div className="col col-4 position-relative no-padding-right">
                <a onClick={this.toggleSort}>
                  ترتيب
                  {sortOpen ? (
                    <IoIosArrowUp style={arrowStyle} />
                  ) : (
                    <IoIosArrowDown style={arrowStyle} />
                  )}
                </a>
                {sortOpen && (
                  <MenuDropDown
                    array={sortList}
                    togglePopup={() => this.toggleSort()}
                    open={sortOpen}
                    onClick={(element) => {
                      this.toggleSort();
                      this.handleSort(element);
                      console.log(element);
                    }}
                  />
                )}
              </div>

              <div className="shop-options__icons col col-6 no-padding-left">
                <FaFilter className="icon m-left" />
                <div className="shop-options__icons--grid">
                  <IoMdGrid className="icon" />

                  <IoMdCard className="icon" />
                </div>
              </div>
            </div>
            <div>
              {this.props.products.isLoading ? (
                <div>Loading...</div>
              ) : (
                <LoadMoreCards
                  grid={this.state.grid}
                  limit={this.state.limit}
                  size={this.props.products.toShopSize}
                  products={this.props.products.toShopProducts}
                  loadMore={this.loadMoreAction}
                  cardComponent={CardEvents}
                  filterData={this.state.filterData} //WE PASS TO TH CARD
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
  typeCategories: state.typeCategories,
});

const mapDispatchToProps = {
  getProductsToShop,
  getCategories2,
  clearShopProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopIndex);
