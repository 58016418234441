import React, { Component } from "react";


export default class CardBlockShop extends Component {
  renderCards = (list, grid) => {
    debugger
    const {cardComponent:Card,lowestPrice,filterData} =this.props;
  return  list
      ? list.map((card, i) => <Card filterData={filterData} key={card._id} lowestPrice={lowestPrice} {...card} grid={grid} />)
      : null;
  };
  render() {
    const { list, grid } = this.props;
    return (
      <div >
        <div>
          <div className="cardBlockShop">
            {list ? (
              list.length === 0 ? (
                <div className="no_result">لايوجد نتائج</div>
              ) : null
            ) : null}
            {this.renderCards(list, grid)
          
            }
          </div>
        </div>
      </div>
    );
  }
}
