import React, { Component, useEffect, useRef } from "react";
import { returnName } from "../../helpers";
import { connect } from "react-redux";

function MenuDropDown(props) {
  debugger;
  const menuRef = useRef();
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const { open, array, globalProps, togglePopup, onClick, double } = props;
  const handleClick = (e) => {
    if (menuRef.current.contains(e.target)) {
      // Inside the event (handleClick) this.node.contains(e.target) will return true if whatever you are clicking is inside the “node” ref.
      console.log("ss");
      return;
    }
    togglePopup();
    console.log("ddd");
  };
  console.log(array);

  return (
    <ul
      ref={menuRef}
      className={`menu-dropdown menu-dropdown__form ${
        open && "menu-dropdown__open menu-dropdown__open--form"
      } 
      ${double && "menu-dropdown__open--doubleSize"}`}
    >
      {array &&
        array.List &&
        array.List.length > 0 &&
        array.List.map((elemen, i) => (
          <li
            key={i}
            id="menu-item-8047"
            className={`menu-dropdown__item -${globalProps.themeClass}`}
            onClick={() => onClick(elemen)}
          >
            {elemen.Component && (
              <elemen.Component
                size="30px"
                className={`icon menu-dropdown__item--icon `}
              />
            )}
            {returnName(elemen)}
          </li>
        ))}
    </ul>
  );
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  //EventsCateogries:state.eventsCategories
});

export default connect(mapStateToProps)(MenuDropDown);
