import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getProductByID,
  resetErrors,
  getProductsToShop,
  clearProductById,visitProduct,getVisitorsInfo
} from "../../../actions";
import { toast } from "react-toastify";
import DetailBox from "../../shared/productDetail/DetailBox";
import EventsForm from "../EventsForm";
import CustomComponent from "./CustomComponent";

import ResError from "../../shared/ResError";

class ProductDetailsIndex extends Component {
  constructor() {
    super();
    this.state = {
      success: false,
      formError: false,
      errorMessage: "",
      isPending: false,
    };
  }
  componentDidMount() {
    const id = this.props.match.params.id;

    this.props.getProductByID(id, "eventsProducts");
   
    getVisitorsInfo().then(res=>{
     
     localStorage.setItem(`events-${id}`,'visited');                
     visitProduct(id,'events',res.ip).then(res=>{
      console.log(res)
     }).catch(err=>console.log(err))
    console.log(res);
   }).catch(err=> console.log(err))
  }
  componentDidUpdate() {
      // const {errors} = this.props.productDetail;
      //       // if (errors && errors.length > 0) {
      //  this.props.clearProductById()
      //   // toast.error(errors[0].message);
        
      // }
      
  }
  onFormQuery = (e, formData) => {
    e.preventDefault();
    console.log(formData);

    if (
      formData.date &&
      formData.date.startAt === "" &&
      formData.date.endAt === ""
    ) {
      this.setState({
        formError: true,
        errorMessage: "يجب عليك ان تحدد تاريخاً  ",
        success: false,
        isPending: false,
      });
    } else {
      this.setState({ success: false, formData: false, isPending: true });
      const filterData = { ...formData };
      filterData.filters._id = this.props.match.params.id;
      this.props
        .getProductsToShop(
          "eventsProducts",
          0,

          100,
          "_id",
          "asc",

          filterData,
          []
        )
        .then((res) => {
          if (res && res.length > 0) {
            res.length === 1 &&
              this.setState(
                {
                  success: true,
                  isPending: false,
                  formError: false,
                  bookingData: {
                    date: filterData.date,
                    id: this.props.match.params.id,
                    product:res[0],
                    quantity: filterData.filters.quantity[0],
                  },
                },
                () => {
                  toast.success("يتم تحويلك...");
                setTimeout(() => {
                    this.props.history.push({
                        pathname: "/events/confirmBooking",
                        state: { filterData: this.state.bookingData },
                      });
                }, 2500);
                }
              );

            res.length > 1 &&
              this.setState({
                formError: true,
                errorMessage: "خطأ نادر! حاول اعادة تحميل الصفحة",
                success: false,
                isPending: false,
              });
          } else {
            this.setState({
              formError: true,
              errorMessage: "محجوز في هذا التاريخ",
              success: false,
              isPending: false,
            });
          }
        })
        .catch((err) => {
          
          this.setState({
            formError: true,
            errorMessage: err[0] ? err[0].message : "خطأ أعد المحاولة",
            success: false,
            isPending: false,
          });
        });
    }
  };

  render() {
    const { isLoading, productDetail, errors } = this.props.productDetail;
    const { formError, errorMessage, isPending } = this.state;
    // if (errors && errors.length > 0) {
    //  // this.props.clearProductById()
    //    toast.error(errors[0].message);
       
    //  }
    return (
      <div>
        <div className="productDetail--form">
          {/* <MainEvents small /> */}
        </div>
        <div className=" container">
          {errors && errors.length>0 && <ResError errors={errors}/>}
          {isLoading ? (
            <h1>Loading...</h1>
          ) : productDetail._id ? (
            <div className="productDetail">
              <DetailBox
                CustomComponent={CustomComponent}
                RightForm={EventsForm}
                onFormQuery={this.onFormQuery}
                productDetail={productDetail}
                formError={formError}
                errorMessage={errorMessage}
                isPending={isPending}
                type="events"
                filterData={
                  this.props.location.state && {
                    ...this.props.location.state.filterData,
                  }
                }
              />
            </div>
          ) : (
            <ResError errors={[{message:"خطأ"}]}/>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productDetail: state.productDetail,
});

const mapDispatchToProps = {
  getProductByID,
  resetErrors,
  getProductsToShop,
  clearProductById
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailsIndex);
