import React, { Component } from 'react'
import { connect } from 'react-redux'

 const UserData = ({auth}) => {
    debugger
    return (
        <div class="user-profile row align-center">
        <img
          src={`${process.env.PUBLIC_URL}/images/blogger.svg`}
          alt="خلفية لقسم حجز الحفلات في اليمن"
        />
        <div class="user-details">
          <h4>{auth.username.username}</h4>
    <p>{auth.username.email}</p>
        </div>
      </div>
    )
}

const mapStateToProps = (state) => ({
    auth:state.auth
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(UserData)
