import React, { Component } from "react";
import { MdHistory,MdEdit } from "react-icons/md";
import { IoIosHeart } from "react-icons/io";
import UserBookingsList from "./UserBookingsList";
import UserData from "./UserData";
import ListCard from "./ListCard";
import UserLikesList from "./UserLikesList";
import RegisterForm from "../shared/Form/Register/RegisterForm";

export default class UserProfileCopy extends Component {
  state = {
    filterOpen: false,
    historyOpen:true,
    favoritesOpen:false,
    editOpen:false
  };
  openFavorites =(e)=>{
    e.preventDefault()
    this.setState({
      favoritesOpen:true,
      historyOpen:false,
      editOpen:false,

    })
  }
  openHistory =(e)=>{
    e.preventDefault()

    this.setState({
      favoritesOpen:false,
      editOpen:false,

      historyOpen:true
    })
  }
  openEdit =(e)=>{
    e.preventDefault()

    this.setState({
      favoritesOpen:false,
      editOpen:true,

      historyOpen:false
    })
  }
  render() {
    return (
      <div className="container ">
        <div class=" muck-up">
          <div
            class="user-overlay"
            style={{
              background: `url(${process.env.PUBLIC_URL}/images/entertainment-bg.jpg) no-repeat top /contain`,
            }}
          ></div>
          <div class="top ">
           <UserData />
          </div>
          <div class="clearfix"></div>
          <div
            onClick={() =>
              this.setState({ filterOpen: !this.state.filterOpen })
            }
            class={`filter-btn ${this.state.filterOpen ? "open" : ""}`}
          >
            <a onClick={this.openHistory} id="one" href="#">
              <i class="ion-ios-checkmark-outline">
                {" "}
                <MdHistory />
              </i>
            </a>
            <a onClick={this.openEdit} id="two" href="#">
              <i  class="ion-ios-alarm-outline">
                {" "}
                <MdEdit />
              </i>
            </a>
            <a id="three" href="#">
              <i class="ion-ios-heart-outline">
                {" "}
               
              </i>
            </a>
            <a onClick={this.openFavorites} id="all" href="#">
              <i class="ion-ios-star-outline">
                {" "}
                <IoIosHeart />
              </i>
            </a>

            <span class="toggle-btn ion-android-funnel">
              {" "}
              <MdHistory />
            </span>
          </div>
          <div class="clearfix"></div>
          <div class="bottom ">
           {this.state.historyOpen && <UserBookingsList />}
           {this.state.favoritesOpen && <UserLikesList />}
           {this.state.editOpen && <RegisterForm edit />}
          </div>
        </div>
      </div>
    );
  }
}
