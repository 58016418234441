import React, { Component } from "react";
import { IoIosAddCircle, IoIosCalendar, IoIosAlbums } from "react-icons/io";
import moment from "moment";
import { Redirect } from "react-router-dom";

import BookingForm from "../shared/bookingForm/BookingForm";
import RenderFeild from "../shared/bookingForm/RenderFeild";
import MyButton from "../shared/MyButton";
import { getCategories } from "../../actions";
import { connect } from "react-redux";
class ClinicsForm extends BookingForm {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.catRef = React.createRef();
    this.state = {
      popup: {
        address: {
          List: [
            { _id: "صنعاء", name: "صنعاء" },
            { _id: "عدن", name: "عدن" },
            { _id: "ذمار", name: "ذمار" },
            { _id: "تعز", name: "تعز" },
          ],
          open: false,
          valueChoosen: "",
          name: "address",
          isLoading: false,
        },
        categoryName: {
          List: [],
          open: false,
          valueChoosen: "",
          name: "categoryName",
          isLoading: false,
        },
      },

      datePopup: {
        open: false,
        values: {},
      },
      datePopupValues: {
        open: false,
        values: {},
      },
      labelValue: {},
      popupOpen: false,
      filterData: {
        filters: {
          categoryName: [],
          address: [],

        },
        date:"",
      },
    };
  }
  componentDidMount() {
    this._isMounted = true;

    getCategories("Clinics").then((res) => {
      if (this._isMounted) {
        this.setPopupState({ categoryName: res });
      }
    });

    if (this.props.filterData) {
      const nwfilterData = { ...this.state.filterData };
      const { date, filters } = this.props.filterData;
      const {popup} = this.state
    
      
      this.props.filterData.date &&
        (nwfilterData.date = this.props.filterData.date);
        filters&&   Object.keys(filters).forEach((key) => {
        nwfilterData.filters[key] = [...filters[key]];
      
        popup[key] &&
          (popup[key].valueChoosen = filters[key][0]);
      });
      this.setState({
        filterData: nwfilterData,
       popup
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  redirectOnSubmit = (e) => {
    this.setState({
      searchSubmitted: true,
    });
  };
  render() {
    let s = this.state;
    if (this.state.searchSubmitted) {
      return (
        <Redirect
          to={{
            pathname: "/clinics/shop",
            state: { filterData: this.state.filterData },
          }}
        />
      );
    }
    return (
      <form action="" onSubmit={this.submitForm} className="booking-form">
        <div className="row  align-center flex-wrap animate">
          {!this.props.bookForm && (
            <div
              className={`col  ${
                this.props.sideForm ? "col_full" : "col-4 col__border"
              } `}
            >
              <RenderFeild
                label="اختار المنطقة"
                value={this.state.popup.address.valueChoosen}
                type="category"
                togglePopup={() => {
                  this.togglePopup(this.state.popup.address);
                }}
                themeColorClass={this.props.globalProps.themeColorClass}
                catRef={this.catRef}
                icon={IoIosAddCircle}
                renderPopup={() =>
                  this.renderPopup(this.state.popup.address)
                }
              />
            </div>
          )}{" "}
          <div
            className={`col  ${
              this.props.sideForm ? "col_full" : "col-4 col__border"
            } `}
          >
            <RenderFeild
              label="اختار التاريخ"
              value={this.state.filterData.date}
              single={true}
              type="date"
              togglePopup={() => {
                this.toggleDate(true);
              }}
              themeColorClass={this.props.globalProps.themeColorClass}
              icon={IoIosCalendar}
              renderPopup={() => this.renderDatePopup(this.state.datePopup,true)}
            />
          </div>
          <div
            className={`col ${
              this.props.sideForm ? "col_full" : "col-4"
            } row align-center flex-wrap justify-between`}
          >
             {!this.props.bookForm &&  <RenderFeild
                label="اختار الصنف"
                value={this.state.popup.categoryName.valueChoosen}
                type="category"
                togglePopup={() => {
                  this.togglePopup(this.state.popup.categoryName);
                }}
                themeColorClass={this.props.globalProps.themeColorClass}
                catRef={this.catRef}
                icon={IoIosAddCircle}
                renderPopup={() =>
                  this.renderPopup(this.state.popup.categoryName)
                }
              />}
            {!this.props.bookForm ? (
              <MyButton
                value="بحث"
                buttonType="bold"
                onClick={
                  this.props.sideForm
                    ? (e) => this.props.onClick(e, this.state.filterData)
                    : this.submitForm
                }
                utilClass={` cta-btn--large col-6 w-100`}
                linkClasses={`col ${
                  this.props.sideForm ? "col_full no-padding-right" : "col-6"
                } col__no-padding-right`}
              />
            ) : (
              <MyButton
                value={this.props.isPending ? "التحقق..." : "عربن"}
                buttonType="bold"
                onClick={(e) => this.props.onClick(e, this.state.filterData)}
                utilClass={` cta-btn--large col ${
                  this.props.sideForm ? "col_full " : "col-6"
                } col__no-padding-right w-100`}
                disabled={this.props.isPending}
              />
            )}
          </div>
     
            </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
});

export default connect(mapStateToProps)(ClinicsForm);
