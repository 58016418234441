import React from 'react'
import { Link } from 'react-router-dom'

export default function EnterainmentCustomComponent(props) {
    return (
        <div className="detailLeft__custom">
         
            <p>اجمالي ما تتسعه الغرفة: {props.productDetail.roomSize}</p>
        </div>
    )
}
