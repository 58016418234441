import React from "react";

function ClinicHorizontalCard(props) {
  
  const renderImage = (images) => {
    if (images && images.length > 0) {
      return images[0];
    } else {
      return `${process.env.PUBLIC_URL}/images/image_not_available.png`;
    }
  };
  return (
    <div className="row justify-between align-center">
      <img
        style={{ height: 60, width: 60 }}
        src={renderImage(props.photos)}
        alt="صورة المنتج"
      />
      <div className="flex-1" style={{ margin: "0 2rem" }}>
        <h2>الدكتور {props.name}</h2>
        
        <span> وقت الإنتظار : {props.waitingTime}</span>
      </div>
      <div className="row flexColumn align-center">
        <h2>{props.price}</h2>
        <h5>لليوم الواحد</h5>
      </div>
    </div>
  );
}

export default ClinicHorizontalCard;
