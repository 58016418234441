import {
 GET_PRODUCTS_TO_SHOP_INIT,
 GET_PRODUCTS_TO_SHOP_FAILURE,
 GET_PRODUCTS_TO_SHOP_SUCCESS,
 GET_PRODUCTS_TO_SHOP_CLEAR,
 GET_PRODUCT_BY_ID_SUCCESS,
 GET_PRODUCT_BY_ID_INIT,
 GET_PRODUCT_BY_ID_FAILURE,
 GET_PRODUCT_BY_ID_CLEAR,
 GET_TRENDINGS_SUCCESS,
 GET_TRENDINGS_INIT,
 GET_TRENDINGS_FAILURE,
 GET_TRENDINGS_CLEAR,
 GET_PRODUCT_COMPANY_BY_ID_SUCCESS,
  GET_PRODUCT_COMPANY_BY_ID_INIT,
  GET_PRODUCT_COMPANY_BY_ID_FAILURE,
  GET_PRODUCT_COMPANY_BY_ID_CLEAR,
 RESET_ERRORS,
} from "../actions/types";
let INITIAL_STATE = {
  trendings: {
    list: [],
   
    isLoading: false,
    errors: [],
  },
  Products: {
    toShopProducts: [],
    toShopSize:0,
    lowestPrice:0,
    isLoading: false,
    errors: [],
  },
  product:{
    productDetail:{},
    isLoading: false,
    errors: [],
  },
  productCompany:{
    companyDetail:{},
    isLoading: false,
    errors: [],
  }
};
export const productsReducer = (state = INITIAL_STATE.Products, action) => {
  switch (action.type) {
    case GET_PRODUCTS_TO_SHOP_INIT:
      return {
        ...state,
      
        isLoading: true,
      };
    case GET_PRODUCTS_TO_SHOP_SUCCESS:
  
      return {
        ...state,
        isLoading: false,
        toShopProducts: action.payload.products,
        toShopSize:action.payload.size,
        lowestPrice:action.payload.lowestPrice,
        errors:[],
      };
      case GET_PRODUCTS_TO_SHOP_FAILURE:
        return {
          ...state,
          toShopProducts: [],
          toShopSize:0,
          isLoading: false,
          lowestPrice:0,
          errors: action.payload,
        };
        case GET_PRODUCTS_TO_SHOP_CLEAR:
      return {
        ...state,
        toShopProducts: [],
        toShopSize:0,
        isLoading: false,
        lowestPrice:0,
        errors: [],
      };

    default:
      return state;
  }
};


export const productDetailReducer = (state = INITIAL_STATE.product, action) => {
  switch (action.type) {
    case GET_PRODUCT_BY_ID_INIT:
      return {
        ...state,
        productDetail:{},
        errors: [],
              isLoading: true,
      };
    case GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productDetail: action.payload,
        errors:[],
      };
      case GET_PRODUCT_BY_ID_FAILURE:
        return {
    
          productDetail: {},
          isLoading: false,
          errors: action.payload,
        };
        case GET_PRODUCT_BY_ID_CLEAR:
          return {
      
            productDetail: {},
            isLoading: false,
            errors: [],
          };
         
 
    default:
      return state;
  }
}
export const productCompanyDetailReducer = (state = INITIAL_STATE.productCompany, action) => {
  switch (action.type) {
    case GET_PRODUCT_COMPANY_BY_ID_INIT:
      return {
        ...state,
        companyDetail:{},
        errors: [],
              isLoading: true,
      };
    case GET_PRODUCT_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companyDetail: action.payload,
        errors:[],
      };
      case GET_PRODUCT_COMPANY_BY_ID_FAILURE:
        return {
    
          companyDetail: {},
          isLoading: false,
          errors: action.payload,
        };
        case GET_PRODUCT_COMPANY_BY_ID_CLEAR:
          return {
      
            companyDetail: {},
            isLoading: false,
            errors: [],
          };
         
 
    default:
      return state;
  }
}

export const trendingsReducer = (state = INITIAL_STATE.trendings, action) => {
  switch (action.type) {
    case GET_TRENDINGS_INIT:
      return {
        ...state,
      
        isLoading: true,
      };
    case GET_TRENDINGS_SUCCESS:
  
      return {
        ...state,
        isLoading: false,
       list:action.payload,
        errors:[],
      };
      case GET_TRENDINGS_FAILURE:
        return {
          ...state,
          list:[],

          errors: action.payload,
        };
        case GET_TRENDINGS_CLEAR:
      return {
        ...state,
        list:[],

        errors: [],
      };

    default:
      return state;
  }
};