import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IoIosAddCircle, IoIosCalendar } from "react-icons/io";
function MesseginBox(props) {
  const [open,setOpen] = React.useState(false)
  const onClick =  e =>{
    e.preventDefault()
    setOpen(!open)
  }
  return (
    <div className={`messaeginBox ${props.company ? 'company':'general'} ${open ? 'active':''}`}>
    
      <div className={`${props.company ? 'content':''}`}>
     { props.company &&  <div className="text">
          <h3>Kelly Chen</h3>
          <h6>Front-end Web Designer</h6>
        </div>}
        <div onClick={onClick} className={`btn ${open? 'active': ''} ${props.globalProps.themeClass}`}>
          <span></span>
        </div>
      </div>
      <div className={`box ${open? 'open': ''}`}>
        <i className=""><IoIosCalendar  className="icon__inside" size="25px"   /></i>
        <i className=""><IoIosCalendar  className="icon__inside" size="25px"  /></i>
        <i className=""><IoIosCalendar  className="icon__inside" size="25px"  /></i>
        <i className=""><IoIosCalendar  className="icon__inside" size="25px"  /></i>
        <i className=""><IoIosCalendar  className="icon__inside" size="25px"  /></i>
      </div>
     { props.company &&<div className="photo">
        <img
          alt="message"
          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/764024/profile/profile-512.jpg"
        />
      </div>}
    </div>
  );
}


const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(MesseginBox)
