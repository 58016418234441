import React from 'react'
import { connect } from 'react-redux'

export const ServicesLine = ({list,globalProps}) => {
    return (
        <div className="container  border-bottom" style={{marginTop:'2rem'}}>
            <div className="row justify-between align-center">
            {list.map((item,i)=>(
                <div key={i} className="col col-4 row justify-between align-center">
                    <item.Icon size="130" className={`mg-left ${globalProps.themeColorClass}`}/>
                    <div className="row flexColumn ">
                    <h1>{item.title}</h1>
                    <p style={{lineHeight:'1.6'}}>{item.desc}</p>
                    </div>
                </div>
            ))}
        </div>

        </div>
            )
}

const mapStateToProps = (state) => ({
    globalProps: state.globalProps,
  });
  
  const mapDispatchToProps = {};
  

export default connect(mapStateToProps, mapDispatchToProps)(ServicesLine)
