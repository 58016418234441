import React from 'react'
import FormFrame from '../FormFrame'
import RegisterForm from './RegisterForm'
import WithGeneralTheme from '../../../Hoc/themes/WithGeneralTheme'

 class Register extends React.Component {
   render(){
    return (
        <FormFrame title="تسجيل جديد" register>
                <RegisterForm />

        </FormFrame>
    )
   }
}
export default Register
