import React, { Component } from "react";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

export default class Slider extends Component {
  renderImages = () => {
    let images = [...this.props.photos];
    images.map(
      (photo, i) =>
        (images[i] = {
          original: photo,
          thumbnail: photo,
          originalAlt: "عرض منتج",
          thumbnailAlt: "image thumb",
        })
    );
    return images;
  };
  render() {
    const { photos, background } = this.props;
    return (
      <ImageGallery
        showBullets={background}
        showPlayButton={!background}
        showNav={!background}
        infinite={background}
        additionalClass={`${background?'full-slider':''}`}
        showThumbnails={!background}
        thumbnailPosition="left"
        lazyLoad
        isRTL
        items={this.renderImages()}
      />
      //     <Carousel  showArrows={true} >

      //        {photos.map((photo,i)=>(
      //            <div style={{height:"300px",

      //     }}

      //            >
      //                <img src={`${photo}`} alt=""/>
      //            </div>
      //        ))}
      //     </Carousel>
    );
  }
}
