import React from "react";
import { connect } from "react-redux";
import Badge from "./Badge";
import { getTrendings,clearTrendings } from "../../actions";

export const Trending = ({ type,trendings, globalProps,getTrendings,clearTrendings,badge }) => {
  React.useEffect(() => {
    // add when mounted
    getTrendings(type)
    // return function to be called when unmounted
    return () => { 
      clearTrendings()
    };
  }, [])
  const renderImage = (images) => {
    if (images && images.length > 0) {
      return images[0];
    } else {
      return `${process.env.PUBLIC_URL}/images/image_not_available.png`;
    }
  };
  return (
    <div className="container  " style={{ marginTop: "2rem" }}>
      {trendings.loading ? (
        "loading..."
      ) : (
        <div className="row justify-between  flex-wrap ">
          {trendings.list.map((item, i) => (
            <div key={i} className={`col ${i > 1 ? " col-4" : "col-6"} row img-hover`}>
              {" "}
              {
                //the row here and below os for making same height images
              }
              <div
                className="position-relative row overflow-hidden"
                //   style={{
                //     background: `url(${process.env.PUBLIC_URL}/images/${item.image}-bg.jpg) no-repeat `,
                //     backgroundPosition:'center',
                //     backgroundSize:'cover'
                //                       }}
              >
                <img
                  src={renderImage(item.photos)}
                  alt="خلفية لقسم حجز الحفلات في اليمن"
                  className={`position-relative hovered`}
                />
                <div className="overlay "></div>

                <div className="row flexColumn align-center position-middle">
                  <h1 style={{ color: "#fff" }} className="mg-bottom">
                    {item.name}
                  </h1>
                  <Badge
                    value={`${item.value} ${badge}`}
                    backgorund={globalProps.themeClass}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  trendings: state.trendings,
});

const mapDispatchToProps = {
  getTrendings,clearTrendings
};

export default connect(mapStateToProps, mapDispatchToProps)(Trending);
