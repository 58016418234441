import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

import App from './App';
import './sass/_main.scss' 
import * as serviceWorker from './serviceWorker';

const store = require("./reducers").init();
const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
