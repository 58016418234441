import React from 'react';
import DayPicker from 'react-day-picker';
import { Helmet } from 'react-helmet';
import 'react-day-picker/lib/style.css';

export default class SingleDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDay: null,
    };
  }

  handleDayClick(day, { selected }) {
    this.props.onDateChange(day)
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

  render() {
    return (
      <div>
           <p>
          {this.state.selectedDay
            ? this.state.selectedDay.toLocaleDateString()
            : 'اختر تاريخاً 👻'}
        </p>
        <DayPicker
          selectedDays={this.state.selectedDay}
          onDayClick={this.handleDayClick}
        />
       <Helmet>
          <style>{`
 
    .DayPicker-Day{
      padding: .8rem 1rem !important
    } 
    .DayPicker{font-size:1.3rem !important;
    }
`}</style>
        </Helmet>
      </div>
    );
  }
}