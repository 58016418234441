import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import TimesBox from "./TimesBox";

function ClincsCustomComponent(props) {
    const {isLoading,error,toShopProducts} = props.products;
    const productDetail = toShopProducts[0] ? toShopProducts[0]:{}
    debugger
  return (
    isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="detailLeft__custom">
      <p>
        {props.date === "" ||
        moment(new Date(props.date), "YMD").format("YYYY-MM-DD") ===
          moment(new Date(), "YMD").format("YYYY-MM-DD")
          ? "اليوم"
          : moment(props.date, "MD").format("D / M")}
      </p>
      {productDetail.availableTimes?productDetail.availableTimes.length > 0
        ? 
        <>
        <TimesBox onTimeClick={props.onTimeClick} title=   {props.date === "" ||
        moment(new Date(props.date), "YMD").format("YYYY-MM-DD") ===
          moment(new Date(), "YMD").format("YYYY-MM-DD")
          ? "اليوم"
          : moment(props.date, "MD").format("D / M")}
        firstPeriod={productDetail.firstPeriod}
        secondPeriod={productDetail.secondPeriod}
        times={productDetail.availableTimes}/> 
     
            
                      </>
        : ` لايوجد وقت متاح في هذا التاريخ`
    :"خطأ نادر أعد المحاولة"
    }
    </div>
      )
   
  );
}

 
  
  export default ClincsCustomComponent
  