import "rc-collapse/assets/index.css";

import Collapse, { Panel } from "rc-collapse";
import React from "react";
import { IoIosArrowDown, IoIosArrowUp, IoIosContacts } from "react-icons/io";

import CheckBox from "./CheckBox";
import RangeLine from "./RangeLine";

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

function random() {
  return parseInt(Math.random() * 10, 10) + 1;
}

const arrowPath =
  "M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88" +
  ".5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3." +
  "6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5." +
  "2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z";

function expandIcon2({ isActive }) {
  return (
    <i style={{ marginRight: ".5rem" }}>
      <svg
        viewBox="0 0 1024 1024"
        width="1em"
        height="1em"
        fill="currentColor"
        style={{
          verticalAlign: "-.125em",
          transition: "transform .2s",
          transform: `rotate(${isActive ? 90 : 0}deg)`,
        }}
      >
        <path d={arrowPath} p-id="5827"></path>
      </svg>
    </i>
  );
}
function expandIcon({ isActive }) {
  return isActive ? <IoIosArrowUp /> : <IoIosArrowDown />;
}

export default class CollapseComponent extends React.Component {
  state = {
    time: random(),
    accordion: false,
    activeKey: [],
    checked: [],
    value: "1",
  };
  componentDidMount() {
    if (this.props.initOpen) {
      
      this.setState({
        activeKey: ["1"],
        checked:this.props.checkedIndexes
      });
    }
  }
  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (props.checkedIndexes && props.checkedIndexes[0] !== state.checked[0]) {
      
      return {
        checked: props.checkedIndexes,
        
      };
    }
    return null;
  }

 
  onChange = (activeKey) => {
    this.setState({
      activeKey,
    });
  };

  getItems() {
    const items = [];
    for (let i = 0, len = 3; i < len; i++) {
      const key = i + 1;
      items.push(
        <Panel
          header={`This is panel header ${key}`}
          key={key}
          disabled={i === 0}
        >
          <p>{text.repeat(this.state.time)}</p>
        </Panel>
      );
    }
    items.push(
      <Panel header={`This is panel header 4`} key="4">
        <Collapse defaultActiveKey="1" expandIcon={expandIcon}>
          <Panel header={`This is panel nest panel`} key="1" id="header-test">
            <p>{text}</p>
          </Panel>
        </Collapse>
      </Panel>
    );

    items.push(
      <Panel header={`This is panel header 5`} key="5">
        <Collapse defaultActiveKey="1">
          <Panel header={`This is panel nest panel`} key="1" id="another-test">
            <form>
              <label htmlFor="test">Name:&nbsp;</label>
              <input type="text" id="test" />
            </form>
          </Panel>
        </Collapse>
      </Panel>
    );

    return items;
  }

  setActivityKey = () => {
    this.setState({
      activeKey: ["2"],
    });
  };

  toggle = () => {
    this.setState({
      accordion: !this.state.accordion,
    });
  };

  reRender = () => {
    this.setState({
      time: random(),
    });
  };

  renderList = () => {
    if (this.props.list) {
      return this.props.list.map((item, i) => (
        <label
          key={i}
          className="row justify-between align-center "
          style={{ backgroundColor: "" }}
        >
          <span className="font-Default" style={{ marginLeft: 8 }}>
            {item.name || item.categoryName}
          </span>

          <CheckBox
            checked={this.state.checked && this.state.checked.indexOf(item._id) !== -1}
            onChange={() => {
              this.handleCheckedChange(item._id);
            }}
          />
        </label>
      ));
    } else {
      return null;
    }
  };
  handleCheckedChange = (valueId) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(valueId);
    const nwChecked = [...checked];
    if (currentIndex === -1) {
      nwChecked.push(valueId);
    } else {
      nwChecked.splice(currentIndex, 1);
    }
    console.log(nwChecked);
    this.setState(
      {
        checked: nwChecked,
      },
      () => {
        this.props.handleFilters(nwChecked);
      }
    );
  };
  render() {
    const accordion = this.state.accordion;
    const btn = accordion ? "Mode: accordion" : "Mode: collapse";
    const activeKey = this.state.activeKey;
    return (
      <div>
        <Collapse
          className="mg-bottom mg-top border-none border-top"
          style={{paddingTop:"2rem",
        backgroundColor:"transparent"}}
          onChange={this.onChange}
          activeKey={activeKey}
          expandIcon={expandIcon}
        >
          <Panel
            key="1"
            className="ddd"
            forceRender={false}
            header={`${this.props.title} `}
          >
            {this.props.price ? (
              <RangeLine
                maxValue={this.props.maxValue}
                minValue={this.props.minValue}
                handleFilters={this.props.handleFilters}
              />
            ) : this.props.children ? (
              this.props.children
            ) : (
              this.renderList()
            )}
          </Panel>
        </Collapse>
      </div>
    );
  }
}
