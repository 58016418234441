import React from "react";
import { toast } from "react-toastify";

function ResError({ errors, success,successMessage }) {
    
  return (
      <>
      
         { !success && errors && errors.length>0 && <div className="errorMessage">{errors[0].message}</div>}
          { success && <div className="successMessage">{successMessage}</div>}
      
      </>
  )
}

export default ResError;
