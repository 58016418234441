import React, { Component } from "react";
import { MdLocationOn, MdWifi } from "react-icons/md";
import { FiCloudSnow } from "react-icons/fi";
import { GiComputerFan } from "react-icons/gi";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

function Card(props) {
  const renderImage = (images) => {
    if (images.length > 0) {
      return images[0];
    } else {
      return `${process.env.PUBLIC_URL}/images/image_not_available.png`;
    }
  };

  return (
    <div className={`col col-4 col-4__shop ${props.grid ? "card" : "card"}`}>
      <div className="card__wrapper">
        <Link
          to={{
            pathname: `${props.onCardClick}/productDetails/${props.id}`,
            state: { filterData: props.filterData },
          }}
          className="card__wrapper--link"
        ></Link>
        <div
          className="card__img"
          style={{
            background: `url(${renderImage(props.photos)}) no-repeat`,
          }}
        ></div>
        <div className="card__body">
          <div className="card__body--top">
           {!props.general&& <div className="card__body--top--header">
              <MdLocationOn
                size="20"
                className={` ${props.globalProps.themeColorClass}`}
              />
              <span>
                {props.company.address.city +
                  "." +
                  props.company.address.street}
              </span>
            </div>}
            <h1 className="card__body--top--title">{props.name}</h1>
            {!props.hotels && !props.clinic && props.general&&(
              <h4 className="card__body--top--sub">من {props.designName} </h4>
            )}

            {props.hotels && (
              <div className="row mg-top">
                {props.wifi && (
                  <MdWifi
                    size="30"
                    className={`mg-left ${props.globalProps.themeColorClass}`}
                  />
                )}
                {props.air && (
                  <FiCloudSnow
                    size="30"
                    className={`mg-left ${props.globalProps.themeColorClass}`}
                  />
                )}
                {props.fan && (
                  <GiComputerFan
                    size="30"
                    className={`mg-left ${props.globalProps.themeColorClass}`}
                  />
                )}
              </div>
            )}
            {!props.hotels && !props.general && (
              <h3 className="card__body--top--company">
                {" "}
                <span>بواسطة</span>
                <Link
                  to={`${props.onCardClick}/company/${props.company._id}`}
                  className={`card__body--top--company ${props.globalProps.themeColorClass}`}
                >
                  {props.company.companyName}
                </Link>
              </h3>
            )}
          </div>
          {props.clinic && (
            <div className="card__clinicPeriod row justify-between align-center">
              <p>{props.availableMessage}</p>
              <div className="card__clinicPeriod--date">
                <p>
                  {props.date === "" ||
                   moment(new Date(props.date), "YMD").format("YYYY-MM-DD") ===
                   moment(new Date(), "YMD").format("YYYY-MM-DD")
                    ? "اليوم"
                    : moment(props.date,"MD").format("D / M")}
                </p>
              </div>
            </div>
          )}

          <div className="card__body--bottom">
            <div className="card__body--bottom--right">
              <span className="card__body--bottom--right__num position-relative">
                {!props.hotels && (
                  <span className="card__body--bottom--right__percentage">
                    {props.orboon.percentage}%<span>نسبة.العربون</span>
                  </span>
                )}
                {props.hotels && (
                  <span className="card__body--bottom--right__text">
                    تبدأ من{" "}
                  </span>
                )}

                <span className="card__body--bottom--right__orboon">
                  Y{props.orboon.priceToPay}
                </span>
              </span>
              {
                <span className="card__body--bottom--right__text">
                  {props.hotels ? "/ لليلة" : "عربون"}
                </span>
              }
            </div>
            {!props.hotels && (
              <div className="card__body--bottom--left">
                <span className="card__body--bottom--left__text">
                  سعر اصلي/
                </span>
                <span className="card__body--bottom--left__num position-relative">
                  {!props.clinic && (
                    <span className="card__body--bottom--left__info">
                      لليلة
                    </span>
                  )}
                  <span className="card__body--bottom--left__price">
                    {props.price}
                  </span>
                </span>
              </div>
            )}
          </div>

          {/* {props.grid ? (
            <div className="description">{props.description}</div>
          ) : null}
          <div className="actions">
            
            
          </div> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
});

export default connect(mapStateToProps)(Card);
