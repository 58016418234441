import moment from "moment";

export const returnName = (name) => {
  return name.categoryName ? name.categoryName : name.name;
};
export const availablePeriod = (availableOnDate) => {
  let i = 0;
debugger
  let morning = false;
  let evening = false;
  if (availableOnDate.length <= 0) {
    morning = false;
    evening = false;
  } else {
    for (i = 0; i < availableOnDate.length; i++) {
      if (
        moment(availableOnDate[i], "HH:mm").isBefore(moment("12:00", "HH:mm"))
      ) {
        morning = true;
        break;
      }
    }

    for (i = availableOnDate.length - 1; i >= 0; i--) {
      if (
        moment(availableOnDate[i], "HH:mm").isAfter(moment("12:00", "HH:mm"))
      ) {
        evening = true;
        break;
      }
    }
  }

  let message =
    morning && evening
      ? "متاح الفترتين"
      : morning
      ? "متاح الفترة الصباحية"
      : evening
      ? "متاح الفترة المسائية"
      : "غير متاح";

    return message
};
export const getAvailableTimes = (period) => {
  const time = moment(period.startingTime, "HH:mm").locale('en-ca');
  const time2 = moment(period.endingTime, "HH:mm").locale('en-ca');

  let temp = time;
  let availableTimes = [];
  while (!temp.isSame(time2)) {
    availableTimes.push(temp.format("HH:mm"));
    temp = time.add(30, "m");
  }
  !availableTimes.includes(period.endingTime) &&
    availableTimes.push(period.endingTime);
  console.log(availableTimes);
  return availableTimes;
};

export const getArabicType= (typeInEnglish)=>{
  if(typeInEnglish==="events") return "حفلات"
  if(typeInEnglish==="entertianment") return "ترفيه"
  if(typeInEnglish==="hotels") return "فنادق"
  if(typeInEnglish==="clinics") return "عيادات"
}
