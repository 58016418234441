import React from 'react'
import CompanyDetails from '../shared/CompanyDetails/CompanyDetails'

export default (props)=> {
    return (
        <div>
                       <CompanyDetails type="Hotels" id={props.match.params.id}/>

        </div>
    )
}
