import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGIN_INIT,
  RESET_ERRORS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_INIT,
  GET_CATEGORIES_SUCCESS,
  CREATE_BOOKING_INIT,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
  CREATE_BOOKING_CLEAR,
  GLOBAL_PROPS,
  GLOBAL_PROPS_OVERLAY,
  GET_PRODUCTS_TO_SHOP_SUCCESS,
  GET_PRODUCTS_TO_SHOP_FAILURE,
  GET_PRODUCTS_TO_SHOP_INIT,
  GET_PRODUCTS_TO_SHOP_CLEAR,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCT_BY_ID_INIT,
  GET_PRODUCT_BY_ID_FAILURE,
  GET_PRODUCT_BY_ID_CLEAR,
  GET_PRODUCT_COMPANY_BY_ID_SUCCESS,
  GET_PRODUCT_COMPANY_BY_ID_INIT,
  GET_PRODUCT_COMPANY_BY_ID_FAILURE,
  GET_PRODUCT_COMPANY_BY_ID_CLEAR,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_BOOKING_BY_ID_INIT,
  GET_BOOKING_BY_ID_FAILURE,
  GET_BOOKING_BY_ID_CLEAR,
  GET_HISTORY_INIT,
  GET_HISTORY_CLEAR,
  GET_HISTORY_FAILURE,
  GET_HISTORY_SUCCESS
  ,
  GET_LIKES_INIT,
  GET_LIKES_CLEAR,
  GET_LIKES_FAILURE,
  GET_LIKES_SUCCESS,
  GET_TRENDINGS_INIT,
  GET_TRENDINGS_CLEAR,
  GET_TRENDINGS_FAILURE,
  GET_TRENDINGS_SUCCESS
} from "./types";
import authService from "../services/auth-service";

import axiosService from "../services/axios-service";
const apiUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axiosService.getInstance();

///////general
const returnError = (err) => {
  return err.response
    ? err.response.data.errors
    ? err.response.data.errors.length >0?err.response.data.errors:[{message:"خطأ غير معروف"}]
      : [{ message: err.message }]
    : [{ message: err.message }];
};
export const test = () => {
  return {
    type: LOGIN_SUCCESS,
    payload: { isAdmin: false, username: "test" },
  };
};
export const setGlobalProps = (data) => {
  return {
    type: GLOBAL_PROPS,
    payload: data,
  };
};
export const setOverlay = (data) => {
  return {
    type: GLOBAL_PROPS_OVERLAY,
    payload: data,
  };
};

///////auth
export const loginAction = (data) => (dispatch) => {
  dispatch({ type: LOGIN_INIT });

  return axios
    .post(`${apiUrl}/customers/loginCustomer`, data)
    .then((res) => {
      authService.saveToken(res.data.token);
      dispatch(loginSuccess());
      return "success";
    })
    .catch((err) => {
      dispatch(loginfailure(returnError(err)));
      return Promise.reject(returnError(err));
    });
};
export const checkAuth = () => (dispatch) => {
  if (authService.isAuthenticated()) {
    dispatch(loginSuccess());
  }
};
const loginSuccess = () => {
  const username = authService.getUsername();
  return {
    type: LOGIN_SUCCESS,
    payload: username,
  };
};
const loginfailure = (errors) => {
  return {
    type: LOGIN_FAILURE,
    payload: errors,
  };
};
export const registerAction = (data) => (dispatch) => {
  dispatch({ type: LOGIN_INIT });

  axios
    .post(`${apiUrl}/customers/registerCustomer`, data)
    .then((res) => {
      authService.saveToken(res.data.token);
      dispatch(loginSuccess());
    })
    .catch((err) => {
      dispatch(loginfailure(returnError(err)));
    });
};
export const editCustomer = (id,data) =>  {
debugger
  return axiosInstance
    .patch(`${apiUrl}/customers/${id}`, data)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
     return Promise.reject(returnError(err));
    });
};
export const getCustomer = (id) => {
debugger
  return axiosInstance
    .get(`${apiUrl}/customers/${id}`)
    .then((res) => {
      return res.data.foundCustomer
    })
    .catch((err) => {
     return Promise.reject(returnError(err));
    });
};
export const logout = () => {
  authService.removeToken();
  return {
    type: LOGOUT,
  };
};

export const resetErrors = () => {
  return { type: RESET_ERRORS };
};


export const getUserHistory = () => (dispatch) => {
  dispatch({ type: GET_HISTORY_INIT });

  return axiosInstance
    .get(`${apiUrl}/customers/getMyBookings`)
    .then((res) => {
      dispatch({
        type: GET_HISTORY_SUCCESS,
        payload: res.data.bookingsFound,
      });
     
    })
    .catch((err) => {
      dispatch({
        type: GET_HISTORY_FAILURE,
        payload: returnError(err),
      });
     
    });
};

export const getUserLikes = () => (dispatch) => {
  dispatch({ type: GET_LIKES_INIT });

  return axiosInstance
    .get(`${apiUrl}/customers/getMyLikes`)
    .then((res) => {
      dispatch({
        type: GET_LIKES_SUCCESS,
        payload: res.data.likesFound,
      });
     
    })
    .catch((err) => {
      dispatch({
        type: GET_LIKES_FAILURE,
        payload: returnError(err),
      });
     
    });
};
////////////////////Categories
export const getCategories2 = (type) => (dispatch) => {
  dispatch({ type: GET_CATEGORIES_INIT });

  axios
    .get(`${apiUrl}/categories/get${type}Categories`)
    .then((categories) => {
      dispatch({ type: GET_CATEGORIES_SUCCESS, payload: categories.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_CATEGORIES_FAILURE,
        payload: err.response
          ? err.response.data.errors
          : [{ detail: err.message }],
      });
    });
};
export const getCategories = (type) => {
  return axios
    .get(`${apiUrl}/categories/get${type}Categories`)
    .then((categories) => {
      return categories.data.result;
    })
    .catch((err) => {
      return Promise.reject(
        err.response ? err.response.data.errors : [{ detail: err.message }]
      );
    });
};

////////////////////////////////products

export const getProductsToShop = (
  type,
  skip,
  limit,
  getBy,
  order,
  filterDate,
  previousState
) => (dispatch) => {
  console.log(previousState);
  const data = {
    ...filterDate,
  };

  dispatch({ type: GET_PRODUCTS_TO_SHOP_INIT });
  return axios
    .post(
      `${apiUrl}/${type}/filterProducts?sortBy=${getBy}&order=${order}&limit=${100}&skip=${0}`,
      data
    )
    .then((res) => {
      let newState = [...previousState, ...res.data.data.products];
      dispatch({
        type: GET_PRODUCTS_TO_SHOP_SUCCESS,
        payload: { size: res.data.data.size, products: newState },
      });
      return Promise.resolve(res.data.data.products);
    })
    .catch((err) => {
      dispatch({
        type: GET_PRODUCTS_TO_SHOP_FAILURE,
        payload: err.response
          ? err.response.data.errors
          : [{ detail: err.message }],
      });
      return Promise.reject(returnError(err));
    });
};
export const clearShopProducts = () => (dispatch) => {
  dispatch({ type: GET_PRODUCTS_TO_SHOP_CLEAR });
};
export const getProducts = (filters) => (dispatch) => {
  axios
    .post(`${apiUrl}/eventsProducts/filterProducts`, filters)
    .then((categories) => {
      dispatch({ type: GET_CATEGORIES_SUCCESS, payload: categories.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_CATEGORIES_FAILURE,
        payload: err.response
          ? err.response.data.errors
          : [{ detail: err.message }],
      });
    });
};

/////////////////productById
export const clearProductById = () => {
  return { type: GET_PRODUCT_BY_ID_CLEAR };
};

export const getProductByID = (id, type) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_BY_ID_INIT });

  return axios
    .get(`${apiUrl}/${type}/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PRODUCT_BY_ID_SUCCESS,
        payload: res.data.foundProduct,
      });
      return res.data.foundProduct;
    })
    .catch((err) => {
      dispatch({
        type: GET_PRODUCT_BY_ID_FAILURE,
        payload: returnError(err),
      });
      return Promise.reject(returnError(err));
    });
};
export const clearProductCompanyById = () => {
  return { type: GET_PRODUCT_COMPANY_BY_ID_CLEAR };
};
export const getHotelProductsByCompanyID = (id, type, filterData) => (
  dispatch
) => {
  dispatch({ type: GET_PRODUCT_COMPANY_BY_ID_INIT });

  return axios
    .post(`${apiUrl}/${type}/${id}`, { ...filterData })
    .then((res) => {
      dispatch({
        type: GET_PRODUCT_COMPANY_BY_ID_SUCCESS,
        payload: res.data.foundProduct,
      });
      return res.data.foundProduct;
    })
    .catch((err) => {
      dispatch({
        type: GET_PRODUCT_COMPANY_BY_ID_FAILURE,
        payload: returnError(err),
      });
      return Promise.reject(returnError(err));
    });
};

export const likeProduct = (id, type) => {
  debugger
  return axiosInstance
    .post(`${apiUrl}/${type}Products/likeProduct/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const unLikeProduct = (id, type) => {
  return axiosInstance
    .post(`${apiUrl}/${type}Products/unLikeProduct/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};
///////////////Booking
export const createBooking = (data, type) => (dispatch) => {
  dispatch({ type: CREATE_BOOKING_INIT });

  return axiosInstance
    .post(`${apiUrl}/${type}/createBooking`, { ...data })
    .then((res) => {
      dispatch({ type: CREATE_BOOKING_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: CREATE_BOOKING_FAILURE,
        payload: returnError(err),
      });
      return Promise.reject(returnError(err));
    });
};
export const clearBooking = () => (dispatch) => {
  dispatch({ type: CREATE_BOOKING_CLEAR });
};

export const clearBookingById = () => {
  return { type: GET_BOOKING_BY_ID_CLEAR };
};
export const getBookingByID = (id, type) => (dispatch) => {
  dispatch({ type: GET_BOOKING_BY_ID_INIT });

  return axiosInstance
    .get(`${apiUrl}/${type}/getBookingById/${id}`)
    .then((res) => {
      dispatch({
        type: GET_BOOKING_BY_ID_SUCCESS,
        payload: res.data.foundBooking,
      });
      return res.data.foundBooking;
    })
    .catch((err) => {
      dispatch({
        type: GET_BOOKING_BY_ID_FAILURE,
        payload: returnError(err),
      });
      return Promise.reject(returnError(err));
    });
};


////visitors 

export const getVisitorsInfo = (id, type) =>  {
 
  return axiosInstance
    .get(`https://api.ipgeolocation.io/ipgeo?apiKey=0ac6802b600a499c9e240d7b910e8831`)
    .then((res) => {
    
      return res.data
    })
    .catch((err) => {
    
      return Promise.reject(returnError(err));
    });
};

export const addVisitor = (data) =>  {


  return axiosInstance
    .post(`${apiUrl}/visitors/addVisitor`, { ...data })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
    
      return Promise.reject(returnError(err));
    });
};

export const visitProduct = (id, type,ip) => {
  debugger
  return axiosInstance
    .post(`${apiUrl}/${type}Products/visitProduct/${id}/${ip}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};



///trendings
export const clearTrendings = () => (dispatch) => {
  dispatch({ type: GET_TRENDINGS_CLEAR });
};
export const getTrendings = (type) => (dispatch) => {
  dispatch({ type: GET_TRENDINGS_INIT });

  return axiosInstance
    .get(`${apiUrl}/${type}/findTopCompanies`)
    .then((res) => {
      dispatch({
        type: GET_TRENDINGS_SUCCESS,
        payload: res.data,
      });
     
    })
    .catch((err) => {
      dispatch({
        type: GET_TRENDINGS_FAILURE,
        payload: returnError(err),
      });
     
    });
};
export const getCompanyStats = (type,id) =>  {
 
  return axiosInstance
  .get(`${apiUrl}/company/get${type}CompanyWithStats/${id}`)
    .then((res) => {
    
      return res.data
    })
    .catch((err) => {
    
      return Promise.reject(returnError(err));
    });
};