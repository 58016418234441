

import React from 'react'
import Card from '../../shared/cards/Card'

export default function CardHotels(props) {
  return (
   <Card 
   onCardClick={`/hotels/hotelCompany`}
   filterData={props.filterData}
   id={props.company._id}
    grid={props.grid}
    photos={props.company.photos}
    company={props.company}
    
    name={props.company.companyName }
    orboon={{priceToPay:props.lowest  }}
   
    hotels={true}
    wifi={props.wifi}
    fan={props.fan}
    air={props.air}
   />
  )
}



// import React, { Component } from "react";
// import { IoIosLocate } from "react-icons/io";
// import { Link } from "react-router-dom";

// export default class CardEvents extends Component {
//   renderImage = (images) => {
    
//     if (images.length > 0) {
//       return images[0];
//     } else {
//       return `${process.env.PUBLIC_URL}/images/image_not_available.png`;
//     }
//   };
//   render() {
//     const props = this.props;
//     return (
//       <div className={`col col-4 col-4__shop ${props.grid ? "card" : "card"}`}>
//         <Link to="/productDetails" className="card__wrapper">
//           <div
//             className="card__img"
//             style={{
//               background: `url(${this.renderImage(props.photos)}) no-repeat`,
//             }}
//           ></div>
//           <div className="card__body">
//             <div className="card__body--top">
//               <div className="card__body--top--header">
//                 <IoIosLocate className="icon" />
//                 <span>
//                   {props.company.address.city +
//                     "." +
//                     props.company.address.street}
//                 </span>
//               </div>
//               <h1 className="card__body--top--title">{props.name}</h1>
//               <h4 className="card__body--top--sub">من {props.designName} </h4>
//               <h3 className="card__body--top--company">
//                 {" "}
//                 <span>بواسطة</span>
//                 <Link to="/company" className="card__body--top--company">
//                   {props.company.companyName}
//                 </Link>
//               </h3>
//             </div>
//             <div className="card__body--bottom">
//               <div className="card__body--bottom--right">
//                 <span className="card__body--bottom--right__num position-relative">
//                   <span className="card__body--bottom--right__percentage">
//                     {props.orboon.percentage}%<span>نسبة.العربون</span>
//                   </span>
//                   <span className="card__body--bottom--right__orboon">
//                     Y{props.orboon.priceToPay}
//                   </span>
//                 </span>
//                 <span className="card__body--bottom--right__text">عربون</span>
//               </div>
//               <div className="card__body--bottom--left">
//                 <span className="card__body--bottom--left__text">
//                   سعر اصلي/
//                 </span>
//                 <span className="card__body--bottom--left__num position-relative">
//                   <span className="card__body--bottom--left__info">لليلة</span>
//                   <span className="card__body--bottom--left__price">{props.price}</span>
//                 </span>
//               </div>
//             </div>

//             {/* {props.grid ? (
//             <div className="description">{props.description}</div>
//           ) : null}
//           <div className="actions">
            
            
//           </div> */}
//           </div>
//         </Link>
//       </div>
//     );
//   }
// }
