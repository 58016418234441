import React from 'react';
import ReactDOM from 'react-dom';
import InputRange from 'react-input-range';
import { connect } from 'react-redux';
//import "react-input-range/lib/css/index.css"
 class RangeLine extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          value: 3,
          value2: 10,
          value3: 10,
          value4: {
            min: 5,
            max: 10,
          },
          value5: {
            min: 3,
            max: 7,
          },
        };
      }
    
      render() {
        return (
          <form className={`slider slider__${this.props.globalProps.themeClass}`}>
            <InputRange 
            
            step={1}
              maxValue={this.props.maxValue || 500}
              minValue={this.props.minValue ||0}
              formatLabel={value => `${value}K`}
              value={this.state.value5}
              onChange={value5 => this.setState({ value5 })}
              onChangeComplete={value => this.props.handleFilters(value)} />
    
         
            
            {/* <InputRange
            input-range__track input-range__track--active
              step={100}
              // maxValue={this.props.maxValue || 100}
              // minValue={this.props.minValue ||0}
              maxValue={100000}
              minValue={0}
              formatLabel={value => `${value} YR`}
              onChange={value => this.setState({ value5: value })}
              onChangeComplete={value => console.log(value)}
              value={this.state.value5} /> */}
          </form>
        );
      }
    
}
const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  //EventsCateogries:state.eventsCategories
});

export default connect(mapStateToProps)(RangeLine)