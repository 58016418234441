import React from 'react'

export default function Badge({value,backgorund}) {
    return (
        <h3
        className={`${backgorund}`}
        style={{
            padding:'5px 20px',
            borderRadius:'10px'
        }}
        >
            {value}
        </h3>
    )
}
