import React from "react";
import { connect } from "react-redux";
import {
  IoIosCheckmark,
  IoMdCheckbox,
  IoIosCheckboxOutline,
} from "react-icons/io";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";

const Checkbox = ({ className, checked, onChange, globalProps, ...props }) => (
  <div className={`${className} checkboxContainer`}>
    <input
      className="checkboxContainer__hidden"
      onChange={onChange}
      type="checkbox"
      checked={checked}
    />
    <div
      className={`checkboxContainer__styled ${
        checked
          ? `${globalProps.themeClass} checkboxContainer__styled--checked`
          : ""
      }`}
    >
      <IoIosCheckmark
        size="40px"
        style={{
          visibility: `${checked ? "visible" : "hidden"}`,
        }}
      />
      {/* <svg
          style={{
            visibility: `${checked ? "visible" : "hidden"}`,
          }}
          className="checkboxContainer__styled--icon"
          viewBox="0 0 24 24"
        >
          <polyline points="20 6 9 17 4 12" />
        </svg> */}
    </div>
  </div>
);
const Checkbox2 = ({ className, checked, onChange, globalProps, ...props }) => (
  <div className={`${className} checkboxContainer`}>
    <input
      className="checkboxContainer__hidden"
      onChange={onChange}
      type="checkbox"
      checked={checked}
    />
    {checked ? (
      <IoMdCheckbox
      
        className={` icon
      ${globalProps.themeColorClass} 
      `}
      />
    ) : (
      <MdCheckBoxOutlineBlank
      
        className={` icon
      
      `}
      />
    )}
  </div>
);
const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  //EventsCateogries:state.eventsCategories
});

export default connect(mapStateToProps)(Checkbox2);
