import React from "react";
import CardBlockShop from "../cards/CardBlockShop";
import MyButton from "../MyButton";
import { connect } from "react-redux";

function LoadMoreCards(props) {
  
  return (
    <div>
      <div>
        <CardBlockShop
          lowestPrice={props.lowestPrice}
          cardComponent={props.cardComponent}
        
          grid={props.grid}
          list={props.products}
          {...props}
        />
      </div>
      {/* {props.size > 0 && props.size >= props.limit ? (
          <MyButton
          value="تحميل المزيد"
          buttonType="bold"
          onClick={props.loadMore}
          utilClass={`${props.globalProps.themeClass} cta-btn--larg`}
          
        />
        
      ) : null} */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  //EventsCateogries:state.eventsCategories
});

export default connect(mapStateToProps)(LoadMoreCards);

