import React from "react";
import Likebutton from "./Likebutton";
import { connect } from "react-redux";
import { likeProduct, unLikeProduct } from "../../../actions";

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
function LikeController({ globalTheme, likes, likesCount, auth, type, id }) {
  const [liked, setLiked] = React.useState(likes.includes(auth.username._id));

  const [likeCount, setLikesCount] = React.useState(likesCount);

  React.useEffect(() => {});
  const likeThisProduct = () => {
    debugger;
    setLiked(true);
    setLikesCount(likeCount + 1);

    likeProduct(id, type)
      .then((res) => {})
      .catch((err) => {
        setLiked(false);
        setLikesCount(likeCount - 1);

        alert(err[0].message);
      });
  };
  const unLikeThisProduct = () => {
    debugger;
    setLiked(false);
    setLikesCount(likeCount - 1);

    unLikeProduct(id, type)
      .then((res) => {})
      .catch((err) => {
        setLiked(true);
        setLikesCount(likeCount + 1);

        alert(err[0].message);
      });
  };
  //   const unLikeThisProduct = (cat) => {
  //     const nw = [...cats];
  //     var index = nw.indexOf(cat);
  //     if (index !== -1) nw.splice(index, 1);
  //     setCats(nw);

  //     deleteCategory(section, cat._id)
  //       .then((res) => {
  //         setMessageOfOperation("");
  //       })
  //       .catch((err) => {
  //         setCats([...nw, cat]);
  //         setMessageOfOperation(err[0].message || " خطأ أثناء الحذف");
  //       });
  //   };
  const onLikeClick = () => {
    if (auth.isAuth) {
      liked ? unLikeThisProduct() : likeThisProduct();
    } else {
      alert("تحتاج لتسجيل الدخول");
    }
  };
  return (
    <div className="row  ">
      <p>{likeCount} اعجبوا بهذا</p>
      <Likebutton
        globalTheme={globalTheme}
        onClick={onLikeClick}
        liked={liked}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LikeController);
