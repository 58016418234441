import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGIN_INIT,
  RESET_ERRORS
} from "./../actions/types";
let INITIAL_STATE = {
  isAuth: false,
  errors: [],
  username: "",
  isLoading: false,
};
export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        isLoading: false,
        errors: [],
        username: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuth: false,
        isLoading: false,
        errors: action.payload,
        username: "",
      };

      case LOGOUT:
        return {
          ...state,
          isAuth: false,
          isLoading: false,
          errors: [],
          username: "",
        };
        case RESET_ERRORS:
      return {
        ...state,
       
        errors: [],
      
      };

    default:
      return state;
  }
};
