import React, { Component } from "react";
import { connect } from "react-redux";

export class UserProfile extends Component {
  render() {
    const { isAuth, username } = this.props;
    return (
      <div className="container">
        <div className="profile">
          <div className="profile__user-info row align-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/blogger.svg`}
              alt="خلفية لقسم حجز الحفلات في اليمن"
              className={`profile__user-info--image`}
            />
            <div className="">
              <h2 className="profile__user-info--name">username.name</h2>
              <h4 className="profile__user-info--username">username</h4>
            </div>
          </div>

          <div className="profile__nav">
            <div className="profile__nav--butoon">
              <h1>80</h1>
              <h6>likes</h6>
            </div>
            <div className="profile__nav--butoon">
              <h1>80</h1>
              <h6>likes</h6>
            </div>
            <div className="profile__nav--butoon">
              <h1>80</h1>
              <h6>likes</h6>
            </div>
          </div>
          <div className="profile__body"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
