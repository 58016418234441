import React, { useEffect, useRef } from 'react'

function NumMenuDropDown({elemntToRender,onChange,addValu,minValu,IoIosAdd,IoIosRemove,toggleNumberPopupClose}) {
    
    const menuRef = useRef();
    useEffect(() => {
      // add when mounted
      document.addEventListener("mousedown", handleClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, []);
  
    
    const handleClick = (e) => {
      
      if (menuRef.current.contains(e.target)) {
        // Inside the event (handleClick) this.node.contains(e.target) will return true if whatever you are clicking is inside the menu ref.
        console.log("ss");
        return;
      }
      toggleNumberPopupClose()
      console.log("ddd");
    };
    
    return (
        <ul
        ref={menuRef}
        className={`menu-dropdown menu-dropdown__form ${
          elemntToRender.open && "menu-dropdown__open menu-dropdown__open--form"
        }`}
      >
        {Object.keys(elemntToRender.list).map((key, i) => (
          <li className="menu-dropdown__numItem" key={i}>
            <label>{elemntToRender.list[key].label}</label>
            <div className="menu-dropdown__numItem--wrapper">
             
                <IoIosAdd className="icon" onClick={()=>addValu(elemntToRender,key)}/>
                <input
                  type="text"
                  name="room_num_search"
                  value={elemntToRender.list[key].value}
                 onChange={(element)=>{onChange(element,elemntToRender,key)}}
                  autoComplete="off"
                  readOnly
                  data-min="1"
                  data-max="20"
                />
                <IoIosRemove className="icon" onClick={()=>minValu(elemntToRender,key)}/>
              </div>
  
          </li>
        ))}
      </ul>
 
    )
}

export default NumMenuDropDown
