import React from "react";
import Slider from "../Slider/Slider";
import { MdFavorite, MdCheck } from "react-icons/md";
import CollapseComponent from "../Shop/CollapseComponent";
import { Link } from "react-router-dom";
import LikeController from "../like/LikeController";

function DetailLeft(props) {
  const renderAssets = () => {
    const assetes = props.hotel
      ? props.productDetail.services
      : props.productDetail.assets;
    return (
      assetes &&
      assetes.map((asset, i) => (
        <div
          key={i}
          className="detailLeft__assets--element row align-center col col-6"
        >
          <MdCheck className={`icon ${props.globalProps.themeColorClass}`} />
          <p style={{ marginRight: "1rem" }}>{asset}</p>
        </div>
      ))
    );
  };
  return (
    <div className="detailLeft">
      <div className="detailLeft__title row align-center justify-between">
        <h1>
          {props.hotel
            ? props.productDetail.companyName
            : props.productDetail.name}
        </h1>
        {!props.hotel && <div className="row align-center">
          {/* <span>10 favorites</span>

          <MdFavorite size="35px" /> */}
          <LikeController likesCount={props.productDetail.likesCount} 
          likes={props.productDetail.likes}
          type={props.type}
          id={props.productDetail._id}
          globalTheme={props.globalProps}/>
        </div>}
      </div>
      <div className="detailLeft__slider">
        <Slider photos={props.productDetail.photos} />
      </div>
      {
        //this should be enabled once we add desc property to companies
        !props.hotel && (
          <div className="detailLeft__desc">
            <CollapseComponent
              title="وصف المنتج"
              children={true}
              initOpen={true}
            >
              <div className="detailLeft__contentWrapper">
                <p>
                  ضمن صنف{" "}
                  <Link
                    className={`link ${props.globalProps.themeColorClass}`}
                    to={{
                      pathname: `/${props.type}/shop`,
                      state: {
                        filterData: {
                          filters: {
                            categoryName: [
                              props.productDetail.categoryName._id,
                            ],
                          },
                        },
                      },
                    }}
                  >
                    {props.productDetail.categoryName.name}
                  </Link>
                </p>
                <p className="detailLeft__content">
                  {props.productDetail.description}
                </p>
              </div>
            </CollapseComponent>
          </div>
        )
      }
      <div className="detailLeft__desc">
        <CollapseComponent title="المميزات " children={true} initOpen={true}>
          <div className="detailLeft__contentWrapper">
            <div className="detailLeft__assets row align-center flex-wrap">
              {renderAssets()}
            </div>
          </div>
        </CollapseComponent>
      </div>
      {props.CustomComponent && (
        <div className="detailLeft__desc">
          <CollapseComponent
            title={props.hotel ? "الغرف المتاحة" : "خاص بالمنتج "}
            children={true}
            initOpen={true}
          >
            {props.clinic ? (
              props.CustomComponent
            ) : (
              <props.CustomComponent {...props} />
            )}
          </CollapseComponent>
        </div>
      )}
    </div>
  );
}

export default DetailLeft;
