import React from "react";
import { withRouter } from "react-router-dom";
import MyButton from "../MyButton";

 function FormFrame(props) {
  return (
    <div className={`${props.container?"container":''}`}>
      <div style={props.style} className={`general-form ${props.formClasses}`}>
        <h1 className={`general-form__title ${props.titleClasses}`}>{props.title}</h1>

        <div className={`general-form__body ${props.bodyClasses}`}>
          {props.children}

          <div className={"general-form__footer"}>
            {!props.noLine&&<hr />}
            {props.login && (
              <>
                <p>لست مسجلاً؟ </p>
                <MyButton
                  value="سجل"
                  buttonType={`light`}
                  utilClass={` cta-btn--small `}
                  linkClasses="link"
                  linkTo={{
                    pathname: "/general/register",
                    state: { from: props.location },
                  }}
                />
              </>
            )}
            {props.register && (
              <>
                <p>هل سجلت من قبل </p>
                <MyButton
                  value="سجل دخولك"
                  buttonType={`light`}
                  utilClass={` cta-btn--small `}
                  linkClasses="link"
                  linkTo={{
                    pathname: "/general/login",
                    state: { from: props.location },
                  }}
                />
              </>
            )}
            {props.footerComponent && <props.footerComponent />

            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(FormFrame)