import { GLOBAL_PROPS, GLOBAL_PROPS_OVERLAY } from "./../actions/types";
let INITIAL_STATE = {
  globalProps: {},
  overlay: false,
};
export const globalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_PROPS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
export const globalOverlayReducer = (state = INITIAL_STATE.overlay, action) => {
  switch (action.type) {
    case GLOBAL_PROPS_OVERLAY:
      return {
        overlay: action.payload,
      };

    default:
      return state;
  }
};
