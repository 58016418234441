import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MdLocationOn, MdWifi ,MdChildCare} from "react-icons/md";
import { IoMdMan } from "react-icons/io";
import { FiCloudSnow } from "react-icons/fi";
import { GiComputerFan } from "react-icons/gi";
import { FaChild } from "react-icons/fa";

import MyButton from "../../../shared/MyButton";

export default function HotelCompanyCustomComponent({ productDetail,filterData }) {
    
  const renderImage = (images) => {
    if (images && images.length > 0) {
      return images[0];
    } else {
      return `${process.env.PUBLIC_URL}/images/image_not_available.png`;
    }
  };
  const renderProducts = () =>
    productDetail.products && productDetail.products.length > 0 ? (
      productDetail.products.map((product) => (
        <div kry={product._id} className="horizontal-card row justify-between flex-wrap align-center room">
          <img
            // style={{ height: 150, width: 150 }}
            src={renderImage(product.photos)}
            alt="صورة المنتج"
            className="col col-4 no-padding"
          />
          <div className="col col-6 flex-1" style={{ margin: "0 2rem" }}>
            <h2 className="horizontal-card__room-category">{product.categoryName.name}</h2>
            <p className="horizontal-card__room-name">{product.name}</p>
            <div className="row ">
              {product.wifi && (
                <MdWifi size="25" className={`mg-left theme-hotels-color`} />
              )}
              {product.air && (
                <FiCloudSnow
                  size="25"
                  className={`mg-left theme-hotels-color`}
                />
              )}
              {product.fan && (
                <GiComputerFan
                  size="25"
                  className={`mg-left theme-hotels-color`}
                />
              )}
              <div className="horizontal-card__icon">
              <IoMdMan
                  size="25"
                  className={` theme-hotels-color`}
                />
                <h5>{product.adults}x</h5>
              </div>
              <div className="horizontal-card__icon">
              <MdChildCare
                  size="25"
                  className={` theme-hotels-color`}
                />
                <h5>{product.children}x</h5>
              </div>
            </div>
          </div>
          <div className=" col col-2  row flexColumn align-center">
            <h2>
              <span className="horizontal-card__room-price"> العربون/ </span>
              {product.orboon.priceToPay}{" "}
            </h2>

            <MyButton
              value={"عربن"}
              buttonType="bold"
              utilClass={` cta-btn--large w-100 no-margin`}
              linkClasses={`link  w-100 `}
              linkTo={{pathname:`/hotels/productDetails/${product._id}`,state:{filterData}}}

            />
            <h2>
              <span className="room-price"> السعر/ </span>
              {product.price}{" "}
            </h2>
          </div>
          <Helmet>
            <style>{`
  
 

`}</style>
          </Helmet>
        </div>
      ))
    ) : (
      <p>لايوجد غرف متاحة</p>
    );
  return <div className="detailLeft__custom">{renderProducts()}</div>;
}
