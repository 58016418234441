import React, { Component } from "react";

import MainClinics from "./components/ClinicsPage/MainClinics";

import MainEntertainment from "./components/EntertainmentPage/MainEntertainment";

import MainHotels from "./components/HotelsPage/MainHotels";

import NotFound from "./components/shared/NotFound";

import MainEvent from "./components/EventsPage/MainEvents";

import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "./components/Hoc/Layout";
import { getVisitorsInfo,addVisitor ,checkAuth} from "./actions";
import Entertainment from "./Layouts/Entertainment";
import Hotels from "./Layouts/Hotels";
import "moment/locale/en-ca";

import Events from "./Layouts/Events";
import General from "./Layouts/General";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";
import Clinics from "./Layouts/Clinics";
export class App extends Component {
  componentDidMount() {
    moment.locale("en-ca")
    this.props.checkAuth()  
    
    
    getVisitorsInfo().then(res=>{
     const data = {_id:res.ip,country:res.country_name,city:res.city}
     localStorage.setItem('visitor',JSON.stringify(res));                
     addVisitor(data).then(res=>{
      console.log(res)
     }).catch(err=>console.log(err))
    console.log(res);
   }).catch(err=> console.log(err))
  }
  render() {
   
    console.log(this.props);
    return (
      <Layout>
        <Switch>
          <Route
            path="/clinics"
             component={(props) => <Clinics {...props} />}
          />
          <Route
            path="/hotels"
             component={(props) => <Hotels {...props} />}
          />
          <Route
            path="/entertainment"
             component={(props) => <Entertainment {...props} />}
          />
         
         <Route
            path="/events"
            
             component={(props) => <Events {...props} />}
          />
             <Route
            path="/general"
             component={(props) => <General {...props} />}
          />
        
          <Route path={`/general/not-found`}  component={NotFound} />
          <Redirect from="/"  to="/events"  />
          <Redirect to="/general/not-found" />
        </Switch>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  checkAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
