import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/ar";

const RenderDateDisplay = ({ date, globalProps ,time}) => {
  moment.locale("ar");
  console.log(date);
  return (
    <div className="dateWrapper row align-center">
      <div
        className={`dateDisplay dateDisplay__from ${globalProps.themeClass}`}
      >
        <div className={`${globalProps.themeClass}-border dateDisplay__circle`}>
          {time?"يوم":"من"}
        </div>
      {time?  <p>
          {moment(date).format("dddd")}{" "}
          {moment(date).format("LL")}
        </p> :  <p>
          {moment(date.startAt).format("dddd")}{" "}
          {moment(date.startAt).format("LL")}
        </p>}
        <div
          className={` dateDisplay__from--after ${globalProps.themeClass}`}
        ></div>
      </div>
      <div className={`dateDisplay dateDisplay__to ${globalProps.themeClass}`}>
        <div className={`${globalProps.themeClass}-border dateDisplay__circle`}>
          {time?"الساعة":"الى"}
        </div>

      {time?   <p>
          {moment(time,"HHmm").format("HH:mm a") + "      "}          
        </p>:  <p>
          {moment(date.endAt).format("dddd")} {moment(date.endAt).format("LL")}
        </p>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RenderDateDisplay);
