import React from "react";
import { connect } from "react-redux";

import ResError from "../ResError";
import { getCompanyStats } from "../../../actions";
import Slider from "../Slider/Slider";
import { MdLocationOn, MdCall } from "react-icons/md";
import SocialMedia from "../SocialMedia";
import { IoIosArrowDropleft } from "react-icons/io";
import CardBlockShop from "../cards/CardBlockShop";
import CompanyCard from "./CompanyCard";
import MyButton from "../MyButton";

function CompanyDetails(props) {
  const [state, setState] = React.useState({
    loading: false,
    data: {},
    success: false,
    error: false,
  });

  React.useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    getCompanyStats(props.type, props.id)
      .then((res) => {
        setState({
          loading: false,
          data: res,
          success: true,
          error: false,
        });
      })
      .catch((err) => {
        setState({
          loading: false,
          data: {},
          success: false,

          error: true,
        });
      });
  }, []);
  return (
    <div>
      {state.error && <ResError errors={[{ message: "خطأ" }]} />}

      {state.loading
        ? "loading.."
        : state.success && (
            <>
              <div className={`large-hero `}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/clinics-bg.jpg`}
                  alt="خلفية لقسم حجز الحفلات في اليمن"
                  className={`large-hero__image`}
                />
                <div className="large-hero__overlay"></div>
                <div className="large-hero__content">
                  <div className="wrapper">
                    <div>
                      <div className="container">
                        <div className="row justify-between  flex-wrap">
                          <div className=" col-6__margin  w-100 row justify-between flexColumn">
                            <div className="col col-12 paper w-100">
                              <h1 className="font-big">
                                {state.data.companyName}
                              </h1>
                              <h5 className="font-Default ">
                                {state.data.username}@
                              </h5>
                            </div>
                            <div className="col col-12 paper w-100">
                              <div className="row justify-around align-center">
                                <div>
                                  {" "}
                                  <h1
                                    className={`font-big ${props.globalProps.themeColorClass}`}
                                  >
                                    {state.data.likes}
                                  </h1>
                                  <h5 className="font-Default ">
                                    عدد التفضيلات
                                  </h5>
                                </div>
                                <div>
                                  <h1
                                    className={`font-big ${props.globalProps.themeColorClass}`}
                                  >
                                    {state.data.productsLength}
                                  </h1>
                                  <h5 className={`font-Default `}>
                                    عدد المنتجات
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col col-6__margin paper w-100">
                            <Slider background photos={state.data.photos} />
                            {console.log(state.data)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="papers container">
                <div className="row justify-between  flex-wrap">
                  <div className="col col-6__margin paper w-100">
                    <div className="detailRight__location">
                      <MdLocationOn
                        size="35"
                        className={` ${props.globalProps.themeColorClass}`}
                      />
                      <div className="detailRight__location--details">
                        <p>
                          {state.data.address.city},{state.data.address.street}
                        </p>
                      </div>
                    </div>
                    <div className="detailRight__location">
                      <MdCall
                        size="35"
                        className={` ${props.globalProps.themeColorClass}`}
                      />
                      <div className="detailRight__location--details">
                        <p>
                          {state.data.phones[0]}
                          {state.data.phones[1] && `- ${state.data.phones[1]}`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-6__margin paper w-100">
                 
                    <h5 className="font-Default pd-1">تواصل مع الشركة</h5> 
                    <SocialMedia />
                  </div>
                </div>
              </div>
          <div className="featured container">
            <div className="row justify-between align-center">
              <h1 className="col font-big">منتجات الشركة</h1>
              <MyButton
            value="المزيد >"
            linkTo= {{
              pathname: `/${props.type}/shop`,
              state: {
                filterData: {
                  filters: { company: [state.data._id] },
                },
              },
            }}
           
            linkButton={true}
            linkClasses={"link"}
          />
              {/* <a
                    className={`col __li--link link -${props.globalProps.themeClass}`}
                    href="#"
                  >
                    المزيد
                 
                      <IoIosArrowDropleft  />
                    
                  </a> */}
            </div>
          <div className="">
          <CardBlockShop
          
          cardComponent={CompanyCard}
        
         
          list={state.data.products}
          {...props}
        />
          </div>
          </div>
            </>
          )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
