import {
  CREATE_BOOKING_INIT,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
  CREATE_BOOKING_CLEAR,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_BOOKING_BY_ID_INIT,
  GET_BOOKING_BY_ID_FAILURE,
  GET_BOOKING_BY_ID_CLEAR,
  
} from "../actions/types";
let INITIAL_STATE = {
  createBooking: {
    isLoading: false,
    success: false,
    bookedId: "",
    errors: [],
  },
  booking: {
    bookingDetail: {},
    isLoading: false,
    errors: [],
  },
};
export const createBooking = (state = INITIAL_STATE.createBooking, action) => {
  switch (action.type) {
    case CREATE_BOOKING_INIT:
      return {
        ...state,
        bookedId: "",
        errors: [],
        success: false,
        isLoading: true,
      };
    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        bookedId: action.payload._id,
        errors: [],
      };
    case CREATE_BOOKING_FAILURE:
      return {
        ...state,
        bookedId: "",
        success: false,
        isLoading: false,
        errors: action.payload,
      };
    case CREATE_BOOKING_CLEAR:
      return {
        bookedId: "",
        success: false,
        isLoading: false,
        errors: [],
      };

    default:
      return state;
  }
};

export const bookingDetailReducer = (state = INITIAL_STATE.booking, action) => {
  switch (action.type) {
    case GET_BOOKING_BY_ID_INIT:
      return {
        ...state,
        bookingDetail: {},
        errors: [],
        isLoading: true,
      };
    case GET_BOOKING_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bookingDetail: action.payload,
        errors: [],
      };
    case GET_BOOKING_BY_ID_FAILURE:
      return {
        bookingDetail: {},
        isLoading: false,
        errors: action.payload,
      };
    case GET_BOOKING_BY_ID_CLEAR:
      return {
        bookingDetail: {},
        isLoading: false,
        errors: [],
      };

    default:
      return state;
  }
};
