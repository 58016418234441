import React, { Component } from "react";
import { connect } from "react-redux";
import { FaMoneyBillAlt } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";

class DetailRight extends Component {
  constructor() {
    super();

    this.state = {
      shadow: false,
      upOverlay: false,
    };
  }
  componentWillUnmount() {
    this.props.renderOverlay(false);
  }
  onclick = (e, filter) => {
    e.preventDefault();
    this.props.onFormQuery(e, filter);
  };
  toggleShadow = () => {
    this.setState({
      shadow: !this.state.shadow,
    });
  };
  paneDidMount = (node) => {
    if (node) {
      node.addEventListener("scroll", this.handleScroll.bind(this));
    }
  };
  handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      alert("hitten");
    }
  };

  render() {
    const props = this.props;
   
    return (
      <div onClick={(e) => props.renderOverlay(true)} className={`upOverlay`}>
        <div
          ref={this.paneDidMount}
          className={`general-form ${!this.state.shadow && "no-shadow"} ${
            props.globalProps.themeClass
          }-border`}
        >
          <h1 className={`general-form__title ${props.globalProps.themeClass}`}>
            {props.title}
          </h1>

          <div className={`general-form__body detailRight `}>
            <div className="detailRight__body">
              <div className="detailRight__price">
                <div className="detailRight__price--element">
                  <FaMoneyBillAlt
                    className={`icon ${props.globalProps.themeColorClass}`}
                  />
                  <h5 className="detailRight__price--full">YR{props.price}</h5>
                  <h6>{props.hotel ? "أقل سعر كلي" : "السعر الكلي"}</h6>
                </div>
                <div className="detailRight__price--element">
                  <GiPayMoney
                    className={`icon ${props.globalProps.themeColorClass}`}
                  />
                  <h5 className="detailRight__price--orboon">
                    YR{props.orboon}
                  </h5>
                  <h6>{props.hotel ? "اقل عربون" : "العربون"}</h6>
                </div>
              </div>
              <hr />
              <div className="detailRight__location">
                <MdLocationOn
                  size="35"
                  className={` ${props.globalProps.themeColorClass}`}
                />
                <div className="detailRight__location--details">
                  <p>
                    {props.company.address.city},{props.company.address.street}
                  </p>
                  <p>
                    بواسطة{" "}
                    <Link
                      className={`link ${props.globalProps.themeColorClass}`}
                      to={`/${props.type}/company/${props.company._id}`}
                    >
                      {props.company.companyName}
                    </Link>
                  </p>
                </div>
              </div>
              <hr />
              {!props.hotel && (
                <div className="detailRight__form">
                   {/* {props.clinic&&props.CustomComponent} */}
                  <props.BookingComponent
                    sideForm
                    bookForm
                    onClick={this.onclick}
                    isPending={props.isPending}
                    filterData={props.filterData}
                  />
                  {props.formError && (
                    <p className="error">{props.errorMessage}</p>
                  )}
                </div>
              )}
            </div>

            <div className={"general-form__footer detailRight__footer"}>
              <hr />
              <p>دفع الباقي يكون عند الحضور</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // globalProps: state.globalProps,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailRight);
