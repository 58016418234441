import React from "react";
import MyButton from "./MyButton";

function SummeryBox(props) {
  const customClasssName = `summery-${props.size}`;
  
  return (
    <div  className={`summery ${customClasssName}`}>
      
      <div className={` ${customClasssName}__price`}>
        <div
          className={`row justify-between align-center ${customClasssName}__price--full`}
        >
          <h3>السعر الاجمالي:</h3>
          <h3>{props.price} ريال</h3>
        </div>
        <div
          className={`row justify-between align-center ${customClasssName}__price--discount`}
        >
          <h3>نسبة العربون:</h3>
          <h3>{props.percentage}%</h3>
        </div>
        <hr />
        <div
          className={`row justify-between align-center ${customClasssName}__price--total`}
        >
          <h3>العربون (ماستدفعه)</h3>
          <h3>{props.orboon} ريال</h3>
        </div>
      </div>
      <p>
        نسبة العربون محددة من قبل الشركة المقدمة للمنتج، مع الغاء الكسور بعد
        الآلاف
      </p>
      <div className={` ${customClasssName}__submit`}>
    
       <div className={`${customClasssName}__submit--button`}>
       <p>
          بالضغط على زر الحجز انت توافق على{" "}
          <MyButton
            value="بنود الخدمة"
            linkTo="/general/serviceTerms"
            linkButton={true}
            linkClasses={"link"}
          />
        </p>
        <MyButton
          value={props.isLoading ? "يتم الحجز..." : "عربن"}
          buttonType="bold"
          onClick={(e) => props.onClick(e)}
          utilClass={` cta-btn--large col 
                  col_full 
                  no-margin w-100`}
          disabled={props.isLoading}
        />
       </div>
       <div
          className={`col no-padding-left row flexColumn  ${customClasssName}__price--total ${customClasssName}__submit--total`}
        >
         
          <h3>{props.orboon}</h3>
          <h3>ريال</h3>
        </div>
      </div>
    </div>
  );
}

export default SummeryBox;
