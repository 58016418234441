import React from 'react'
import { IoLogoFacebook,IoLogoTwitter,IoLogoWhatsapp } from 'react-icons/io'
import { MdMessage } from 'react-icons/md'
import MyButton from './MyButton'

export default function SocialMedia() {
    return (
        <div className="footer-social-icons">
    
        <ul className="social-icons">
            <li><a  className="social-icon"> <i className="ic-facebook">
                <IoLogoFacebook className="position-middle" size="25px" />
                </i></a></li>
            <li><a  className="social-icon"> <i className="ic-twitter">
                <IoLogoTwitter className="position-middle" size="25px" />
                </i></a></li>
            <li><a  className="social-icon"> <i className="ic-whatsapp">
                <IoLogoWhatsapp className="position-middle" size="25px" />
                </i></a></li>
              
            {/* <li><a  className="social-icon"> <i className="position-middle ic-youtube">
            <MdMessage className="" size="25px" />
            </i>
            <span>تواصل فوري</span>
            
            </a></li> */}
            {/* <li><a  className="social-icon"> <i className="ic-linkedin"></i></a></li>
            <li><a  className="social-icon"> <i className="ic-github"></i></a></li> */}
        </ul>
    </div>
    )
}
