import {
 
  GET_HISTORY_INIT,
  GET_HISTORY_CLEAR,
  GET_HISTORY_FAILURE,
  GET_HISTORY_SUCCESS,
  GET_LIKES_INIT,
  GET_LIKES_CLEAR,
  GET_LIKES_FAILURE,
  GET_LIKES_SUCCESS
} from "../actions/types";
let INITIAL_STATE = {
  history: {
    isLoading: false,
    bookings:[],
    errors: [],
  },
  likes: {
    isLoading: false,
    likes:[],
    errors: [],
  },
};
export const userHistory = (state = INITIAL_STATE.history, action) => {
  switch (action.type) {
    case GET_HISTORY_INIT:
      return {
        ...state,
        isLoading: true,
        bookings:[],
        errors: [],
    
      };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bookings:action.payload,
        errors: [],
    
      };
    case GET_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        bookings:[],
   
        errors: action.payload,
      };
   

    default:
      return state;
  }
};
export const userLikes = (state = INITIAL_STATE.likes, action) => {
  switch (action.type) {
    case GET_LIKES_INIT:
      return {
        ...state,
        isLoading: true,
        likes:[],
        errors: [],
    
      };
    case GET_LIKES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        likes:action.payload,
        errors: [],
    
      };
    case GET_LIKES_FAILURE:
      return {
        ...state,
        isLoading: false,
        likes:[],
   
        errors: action.payload,
      };
   

    default:
      return state;
  }
};

