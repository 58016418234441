import React from 'react'
import routes from '../routes'
import { Route,Switch,Redirect } from 'react-router-dom';
import ProtectedRoute from '../components/Hoc/auth/ProtectedRoute';
import { connect } from 'react-redux';
import { setGlobalProps } from "../actions";


const switchRoutes = (
    <Switch>
      {routes.EntertainmentRoute.map((prop, key) => {
          
        if (!prop.protected && !prop.logged) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              
            />
          );
        }else if(prop.protected){
           return <ProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        }
        return null;
      })}
      <Redirect from="/entertainment" to="/entertainment/home" />
    </Switch>
  );
  class Entertainment extends React.Component {
    componentDidMount() {
      this.props.setGlobalProps({
        themeClass: "theme-entertainment",
        themeColorClass: "theme-entertainment-color",
      });
    }
    render() {
      return <div>{switchRoutes}</div>;
    }
  }

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setGlobalProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Entertainment);
