import React from "react";
import moment from "moment";

export default function RenderFeild({
  value,
  label,
  type,
  togglePopup,
  themeColorClass,
  catRef,
  icon: Icon,
  renderPopup,
  className,
  values,
  single
}) {
  const renderTemplate = () => {
    let formTemplate = null;

    switch (type) {
      case "category":
        formTemplate = (
          <div
            className={`
          ${className} booking-form-group feild-destination position-relative`}
            onClick={togglePopup}
          >
            <Icon
              size="35px"
              className={`icon booking-form-group__icon ${themeColorClass}`}
            />
            <div className="booking-form-group__dropdown">
              <label className="booking-form-group__dropdown--label">{label}</label>
              <div className="">
                {values ? (
                  Object.keys(values).map((key, i) => (
                    <span
                      key={i}
                      className={`booking-form-group__dropdown--value ${themeColorClass}`}
                      style={{ fontSize: "1.2rem" }}
                    >
                      {values[key].label +":"+ values[key].value + " " }
                    </span>
                  ))
                ) : (
                  <span
                    className={`booking-form-group__dropdown--value ${themeColorClass}`}
                  >
                    {value || "اختار"}{" "}
                  </span>
                )}
              </div>
              <input
                type="hidden"
                name="location_name"
                //value={this.state.labelValue.name}
                ref={catRef}
              />
            </div>
            {renderPopup()}
          </div>
        );
        break;
      case "date":
        
        formTemplate = (
          <React.Fragment>
            <div
              className={`
            ${className} booking-form-group feild-destination position-relative`}
              onClick={togglePopup}
            >
              <Icon
                size="35px"
                className={`icon booking-form-group__icon ${themeColorClass}`}
              />
              <div className="booking-form-group__dropdown">
                <label className="booking-form-group__dropdown--label">{label}</label>
                <div className="">
                  <span
                    className={`booking-form-group__dropdown--value ${themeColorClass}`}
                  >
                    {/* {(moment(value.startAt, moment.ISO_8601, true).isValid() &&
                      moment(value.endAt, moment.ISO_8601, true).isValid()) ?
                     ( moment(value.endAt).format("YY/MM/DD") +
                        "-" +
                        moment(value.startAt).format("YY/MM/DD"))  : 'DD/YY/MM'} */}
                    {(value.startAt &&
                      value.startAt !== "" &&
                      value.endAt &&
                      value.endAt !== "")?moment(value.endAt).format("YY/MM/DD") +
                      "-" +
                      moment(value.startAt).format("YY/MM/DD") : single && value!==""? moment(value).format("YY/MM/DD"): "DD/YY/MM" }
                  </span>
                </div>
              </div>
              {renderPopup()}
            </div>
          </React.Fragment>
        );
        break;

      default:
        formTemplate = null;
    }
    return formTemplate;
  };
  return renderTemplate();
}
