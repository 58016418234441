import React from 'react'
import { Link } from 'react-router-dom'

export default function CustomComponent(props) {
    return (
        <div className="detailLeft__custom">
             <p>
               اسم التصميم{" "}
              <Link
                className={`link link--hover ${props.globalProps.themeColorClass}`}
                to={{
                  pathname: "/events/shop",
                  state: {
                    filterData: {
                      filters: { designName: [props.productDetail.designName] },
                    },
                  },
                }}
              >
                {props.productDetail.designName}
              </Link>
            </p>
            <p>اجمالي الكمية: {props.productDetail.quantity}</p>
        </div>
    )
}
