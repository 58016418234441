import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ResError from "../../shared/ResError";
import WithEventsTheme from "../../Hoc/themes/WithEventsTheme";
import { getBookingByID, clearBookingById } from "../../../actions";
import CustomTable from "../../shared/Table/CustomTable";
import MyButton from "../../shared/MyButton";
import Invoice from "./Invoice";

class BookingDetail extends Component {
  constructor() {
    super();
    this.state = {
      success: false,
      successMessage: "",
    };
  }
  componentDidMount() {
    
    if (this.props.location.state && this.props.location.state.success) {
      
      this.setState({
        success: true,
        successMessage: "تم الحجز",
      });
    }
    const id = this.props.match.params.id;
    this.props.getBookingByID(id, "bookHotel");
  }
  //   componentDidUpdate() {
  //     const { successMessage, success } = this.state;
  //     if (success) {
  //       this.setState({
  //         success: false,
  //         successMessage: " ",
  //       });
  //     }
  //   }
  render() {
    const { successMessage, success } = this.state;
    const { isLoading, bookingDetail, errors } = this.props.bookingDetail;

    return (
      <div className="container">
        <ResError success={success} successMessage={successMessage} />
        {errors && errors.length > 0 && <ResError errors={errors} />}
        {isLoading ? (
          <h1>Loading...</h1>
        ) : bookingDetail._id ? (
          <div className="bookingDetail">
           
            {/* <CustomTable
              array={[
                { name: "المنتج", value:  <MyButton
                value={bookingDetail.product.name}
                linkTo={`/hotels/productDetails/${bookingDetail.product._id}`}
                linkButton={true}
                linkClasses={"link"}
              />  },
                { name: "البداية", value: moment(bookingDetail.startAt).format("YYYY/MM/DD") },
                { name: "النهاية", value:  moment(bookingDetail.endAt).format("YYYY/MM/DD") },
                
                { name: "الأيام", value: bookingDetail.days },
                { name: "المجموع", value: bookingDetail.totalPrice },
                { name: "المدفوع", value: bookingDetail.paid },
                { name: "الباقي", value: bookingDetail.totalPrice -bookingDetail.paid },
              ]}
            /> */}
            <Invoice bookingDetail={bookingDetail} />
          </div>
        ) : (
          <ResError errors={[{ message: "خطأ" }]} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingDetail: state.bookingDetail,
});

const mapDispatchToProps = { getBookingByID, clearBookingById };

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail);
