import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserLikes } from "../../actions";
import ResError from "../shared/ResError";
import { getArabicType } from "../../helpers";
import { Link } from "react-router-dom";

export class UserLikesList extends Component {
  componentDidMount() {
    this.props.getUserLikes();
  }

  renderCards = () => {
    const { likes } = this.props.likes;

    return likes.length > 0
      ? likes.map((like, i) => (
          <li
            key={like.like.id}
            className={`one ${like.like.type}`}
          >
            <span className="task-title">#{like.like.name}</span>
            <Link
              className={`task-time link link--hover theme-${like.like.type}-color`}
              to={`/${like.like.type}/productDetails/${like.like._id}`}
            >
              عرض المنتج
            </Link>
            <span className="task-cat">
              {like.like.name} .{" "}
              {getArabicType(like.like.type)}
            </span>
          </li>
        ))
      : "لايوجد";
  };
  render() {
    const { isLoading, likes, errors } = this.props.likes;
    return (
      <>
        {errors && errors.length > 0 && <ResError errors={errors} />}
        {isLoading ? (
          "Loading"
        ) : (
          <div className="bottom-wrapper position-relative ">
            <div className="title">
              <h3>مفضلاتك</h3>
              <small>ما اعجبت به</small>
            </div>
            <ul className="tasks">{this.renderCards()}</ul>
          </div>
        )}
      </>
    );
  }
}
{
  /* <li className="tow green hang">
<span className="task-title">Team Meeting</span>
<span className="task-time">2pm</span>
<span className="task-cat">Hangouts</span>
<img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/2.jpg" />
<img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/3.jpg" />
<img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/profile.jpg" />
</li> */
}

const mapStateToProps = (state) => ({
  likes: state.likes,
  globalProps: state.globalProps,
});

const mapDispatchToProps = {
  getUserLikes,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLikesList);
