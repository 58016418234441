import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
export default (props) => {
    const [open, setOpen] = React.useState(false)
    return (
      <>
      
        <Modal    classNames={{
          overlay: 'customModal',
          modal: 'customModal',
        }} open={props.open} onClose={props.onClose} center>
        {props.children}
        </Modal>
      </>
    )
  }