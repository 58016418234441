import thunk from "redux-thunk";

import { authReducer } from "./auth-reducer";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { globalReducer,globalOverlayReducer } from "./global-reduver";
import { eventsCategoriesReducer } from "./categoriesReducer";
import { productsReducer,productDetailReducer,productCompanyDetailReducer,trendingsReducer } from "./productsReducer";
import {createBooking,bookingDetailReducer} from "./bookingReducer";
import { userHistory,userLikes } from "./userReducer";

export const init = () => {
  const reducers = combineReducers({
    auth: authReducer,
    globalProps:globalReducer,
    typeCategories:eventsCategoriesReducer,
    products:productsReducer,
    productDetail:productDetailReducer,
    productCompanyDetail:productCompanyDetailReducer,
    overlay:globalOverlayReducer,
    booking:createBooking,
    bookingDetail:bookingDetailReducer,
    history:userHistory,
    likes:userLikes,
    trendings:trendingsReducer,
  });

  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk)));
  return store;
};
