export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_INIT = "LOGIN_INIT";
export const LOGOUT = "LOGOUT";
export const RESET_ERRORS = "RESET_ERRORS";
export const GLOBAL_PROPS = "GLOBAL_PROPS";
export const GLOBAL_PROPS_OVERLAY = "GLOBAL_PROPS_OVERLAY";
export const GET_CATEGORIES_INIT = "GET_CATEGORIES_INIT";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";
export const GET_PRODUCTS_TO_SHOP_INIT = "GET_PRODUCTS_TO_SHOP_INIT";
export const GET_PRODUCTS_TO_SHOP_CLEAR = "GET_PRODUCTS_TO_SHOP_CLEAR";
export const GET_PRODUCTS_TO_SHOP_SUCCESS = "GET_PRODUCTS_TO_SHOP_SUCCESS";
export const GET_PRODUCTS_TO_SHOP_FAILURE = "GET_PRODUCTS_TO_SHOP_FAILURE";

export const GET_PRODUCT_BY_ID_INIT = "GET_PRODUCT_BY_ID_INIT";
export const GET_PRODUCT_BY_ID_CLEAR = "GET_PRODUCT_BY_ID_CLEAR";
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS";
export const GET_PRODUCT_BY_ID_FAILURE = "GET_PRODUCT_BY_ID_FAILURE";

export const GET_PRODUCT_COMPANY_BY_ID_INIT = "GET_PRODUCT_COMPANY_BY_ID_INIT";
export const GET_PRODUCT_COMPANY_BY_ID_CLEAR = "GET_PRODUCT_COMPANY_BY_ID_CLEAR";
export const GET_PRODUCT_COMPANY_BY_ID_SUCCESS = "GET_PRODUCT_COMPANY_BY_ID_SUCCESS";
export const GET_PRODUCT_COMPANY_BY_ID_FAILURE = "GET_PRODUCT_COMPANY_BY_ID_FAILURE";



export const GET_BOOKING_BY_ID_INIT = "GET_BOOKING_BY_ID_INIT";
export const GET_BOOKING_BY_ID_CLEAR = "GET_BOOKING_BY_ID_CLEAR";
export const GET_BOOKING_BY_ID_SUCCESS = "GET_BOOKING_BY_ID_SUCCESS";
export const GET_BOOKING_BY_ID_FAILURE = "GET_BOOKING_BY_ID_FAILURE";

export const CREATE_BOOKING_INIT = "CREATE_BOOKING_INIT";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";
export const CREATE_BOOKING_CLEAR = "CREATE_BOOKING_CLEAR";

export const GET_HISTORY_INIT = "GET_HISTORY_INIT";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAILURE = "GET_HISTORY_FAILURE";
export const GET_HISTORY_CLEAR = "GET_HISTORY_CLEAR";

export const GET_LIKES_INIT = "GET_LIKES_INIT";
export const GET_LIKES_SUCCESS = "GET_LIKES_SUCCESS";
export const GET_LIKES_FAILURE = "GET_LIKES_FAILURE";
export const GET_LIKES_CLEAR = "GET_LIKES_CLEAR";

export const GET_TRENDINGS_INIT = "GET_TRENDINGS_INIT";
export const GET_TRENDINGS_SUCCESS = "GET_TRENDINGS_SUCCESS";
export const GET_TRENDINGS_FAILURE = "GET_TRENDINGS_FAILURE";
export const GET_TRENDINGS_CLEAR = "GET_TRENDINGS_CLEAR";