import React from "react";
import WithGeneralTheme from "../../../Hoc/themes/WithGeneralTheme";
import LoginForm from "./LoginForm";
import FormFrame from "../FormFrame";

class Login extends React.Component {
  render() {
    return  <FormFrame title="تسجيل دخول" login>
    <LoginForm />

</FormFrame>
  }
}

export default Login;
