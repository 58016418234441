import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../shared/nav/Header";
import { setOverlay } from "../../actions";
import { toast } from "react-toastify";
import MesseginBox from "../shared/MesseginBox";

class Layout extends Component {
  componentDidMount() {
    console.log(process.env);
    toast.configure({
     
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: true,
      pauseOnVisibilityChange: true,
      draggable: true,
      pauseOnHover: true,
      //etc you get the idea
    });
  }
  render() {
    
    return (
      <div className="position-relative">
        <Header />
        <div className="">{this.props.children}</div>
<MesseginBox />
        <br />
        <footer
          style={{
            textAlign: "center",
            padding: "10px",
          }}
        >
          &copy; <strong>mohammedrawhani@gmail.com</strong>
        </footer>
        {this.props.overlay.overlay && (
          <div
            onClick={() => this.props.setOverlay(false)}
            className="overlay"
          ></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  overlay: state.overlay,
  //EventsCateogries:state.eventsCategories
});

export default connect(mapStateToProps, { setOverlay })(Layout);
