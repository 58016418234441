import React, { Component } from "react";
import {
  IoMdGrid,
  IoMdCard,
  IoIosArrowUp,
  IoIosArrowDown,
} from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import CollapseComponent from "../../shared/Shop/CollapseComponent";
import MenuDropDown from "../../shared/MenuDropDown";
import LoadMoreCards from "../../shared/Shop/LoadMoreCards";
import { connect } from "react-redux";
import {
  getProductsToShop,
  getCategories2,
  clearShopProducts,
} from "../../../actions";

import HotelsForm from "../HotelsForm";
import CardHotels from "./CardHotels";

class HotelsShopIndex extends Component {
  constructor() {
    super();
    this.state = {
      sortList: {
        List: [
          { _id: 1, name: "حسب الرائج ", getBy: "bookedTimes", order: "desc" },
          { _id: 1, name: "حسب الأرخص", getBy: "price", order: "asc" },
          { _id: 1, name: "حسب الأغلى", getBy: "price", order: "desc" },
        ],
      },
  
      filterData: {
        filters: {
          categoryName: [],
          price: [],
          
          company: [],
          address: [],
          adults: [1],
          children: [1],
          beds: [1],
        },
        date: { startAt: "", endAt: "" },
      },
      grid: "",
      limit: 3,
      skip: 0,
      order: "desc",
      getBy: "createdAt",
      checked: false,
      modalState: false,
    };
  }
  callProductsToShop = (isChanged, isLoadedMore) => {
    const { limit, skip, getBy, order, filterData } = { ...this.state };
    const skipMore = !isLoadedMore ? skip : skip + limit;
    this.props
      .getProductsToShop(
        'hotelsProducts',
        isChanged ? 0 : skipMore,

        limit,
        getBy,
        order,

        filterData,
        isLoadedMore ? this.props.products.toShopProducts : []
      )
      .then((res) => {
        isChanged && this.setState({ skip: 0 });
        isLoadedMore && this.setState({ skip: skipMore });
      });
  };
  componentDidMount() {
    if (this.props.location.state) {
      this.setState(
        {
          filterData: { ...this.props.location.state.filterData },
        },
        () => {
          this.props.getCategories2("Hotels");
          this.callProductsToShop(false);
        }
      );
    } else {
      this.props.getCategories2("Hotels");
      this.callProductsToShop(false);
    }
  }
  componentWillUnmount() {
    this.props.clearShopProducts();
  }
  handleCheckboxChange = (event) => {
    this.setState({ checked: event.target.checked });
  };
  loadMoreAction = (e) => {
    e.preventDefault();
    this.callProductsToShop(false, true);
  };
  handleSort = (element) => {
    this.setState(
      {
        order: element.order,
        getBy: element.getBy,
      },
      () => this.callProductsToShop(true)
    );
  };
  handleFilters = (filters, type) => {
    console.log(filters);

    const newFilters = { ...this.state.filterData };

    if (type === "price") {
      //let priceValue = this.handlePrices(filters);
      let priceValue = [];
      priceValue[0] = filters.min * 1000;
      priceValue[1] = filters.max * 1000;
      newFilters.filters[type] = priceValue;
    } else {
      newFilters.filters[type] = filters;
    }
    this.setState(
      {
        filterData: newFilters,
      },
      () => this.callProductsToShop(true)
    );
  };
  onFormClick = (e, filterData) => {
    e.preventDefault();
    this.setState(
      {
        filterData: { ...filterData },
      },
      () => {
        this.callProductsToShop(false);
      }
    );
  };
  renderFilterOptions = () => {
    
    return (
      <React.Fragment>
        <div className={`general-form theme-hotels-border`}>
          <h1 className={`general-form__title theme-hotels`}>استعلم</h1>

          <div className="general-form__body no-padding">
            <HotelsForm
              sideForm={true}
              filterData={this.props.location.state &&{ ...this.props.location.state.filterData }}
              onClick={this.onFormClick}
            />
          </div>
        </div>
        <CollapseComponent
          title="السعر"
          price={true}
          maxValue={100}
          minValue={0}
          initOpen={true}
          handleFilters={(filters) => {
            this.handleFilters(filters, "price");
          }}
        />
        <CollapseComponent
          title="التصنيفات"
          initOpen={true}
          checkedIndexes={this.state.filterData.filters.categoryName}
          list={this.props.typeCategories.List}
          handleFilters={(filters) => {
            this.handleFilters(filters, "categoryName");
          }}
        />
      </React.Fragment>
    );
  };

  toggleSort = () => this.setState({ sortOpen: !this.state.sortOpen });
  render() {
    console.log(this.state.filterData);
    const { sortOpen, sortList, data } = this.state;
    const arrowStyle = { margin: "0 5px 0 0", verticalAlign: "sub" };

    return (
      <div className="container">
        <div className="row   flex-wrap">
          <div className="col col-3">{this.renderFilterOptions()}</div>
          <div className="col col-9">
            <div className="shop-options row align-center flex-wrap justify-between">
              <div className="col col-4 position-relative no-padding-right">
                <a onClick={this.toggleSort}>
                  ترتيب
                  {sortOpen ? (
                    <IoIosArrowUp style={arrowStyle} />
                  ) : (
                    <IoIosArrowDown style={arrowStyle} />
                  )}
                </a>
                {sortOpen && (
                  <MenuDropDown
                    array={sortList}
                    togglePopup={() => this.toggleSort()}
                    open={sortOpen}
                    onClick={(element) => {
                      this.toggleSort();
                      this.handleSort(element);
                      console.log(element);
                    }}
                  />
                )}
              </div>

              <div className="shop-options__icons col col-6 no-padding-left">
                <FaFilter className="icon m-left" />
                <div className="shop-options__icons--grid">
                  <IoMdGrid className="icon" />

                  <IoMdCard className="icon" />
                </div>
              </div>
            </div>
            <div>
              {this.props.products.isLoading ? (
                <div>Loading...</div>
              ) : (
                <LoadMoreCards
                  grid={this.state.grid}
                  limit={this.state.limit}
                  size={this.props.products.toShopSize}
                  products={this.props.products.toShopProducts}
                  loadMore={this.loadMoreAction}
                  cardComponent={CardHotels}
                  filterData={this.state.filterData}
                  lowestPrice={this.props.products.lowestPrice}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
  typeCategories: state.typeCategories,
});

const mapDispatchToProps = {
  getProductsToShop,
  getCategories2,
  clearShopProducts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelsShopIndex);
