import React, { Component } from "react";
import { IoIosAddCircle, IoIosCalendar, IoIosAlbums } from "react-icons/io";
import moment from "moment";
import { Redirect } from "react-router-dom";

import BookingForm from "../shared/bookingForm/BookingForm";
import RenderFeild from "../shared/bookingForm/RenderFeild";
import MyButton from "../shared/MyButton";
import { getCategories } from "../../actions";
import { connect } from "react-redux";
class EventsForm extends BookingForm {
  _isMounted = false;
  constructor(props) {
    
    super(props);
    
    this.catRef = React.createRef();
    this.state = {
      popup: {
        categoryName: {
          List: [],
          open: false,
          valueChoosen: "",
          name: "categoryName",
          isLoading: false,
        },
        price: {
          List: [],
          open: false,
          valueChoosen: "",
          name: "price",
        },
        brand: {
          List: [],
          open: false,
          valueChoosen: "",
          name: "brand",
        },
      },
      numberPopup: {
        numberQuantityPopup: {
          name: "numberQuantityPopup",
          open: false,
          list: {
            quantity: {
              value: 1,
              label: "الكمية",
            },
          },
        },
      },
      datePopup: {
        open: false,
        values: {},
      },
      datePopupValues: {
        open: false,
        values: {},
      },
      labelValue: {},
      popupOpen: false,
      filterData: {
        filters: {
          categoryName: [],
          price: [],
          designName: [],
          company: [],
          quantity: [1],
        },
        date: { startAt: "", endAt: "" },
      },
    };
  }
  componentDidMount() {
   this._isMounted = true;
   
    getCategories("Events").then((res) => {
      if(this._isMounted){
      this.setPopupState({ categoryName: res });
      }
    });
    if (this.props.filterData) {
      debugger
      const nwfilterData = { ...this.state.filterData };
      const { date, filters } = this.props.filterData;
      
      const nwnumberQuantityPopup = {
        ...this.state.numberPopup,
      };
      this.props.filterData.date &&
        (nwfilterData.date = this.props.filterData.date);
        filters && Object.keys(filters).forEach((key) => {
          nwfilterData.filters[key] = [...filters[key]];
          nwnumberQuantityPopup.numberQuantityPopup.list[key] &&
            (nwnumberQuantityPopup.numberQuantityPopup.list[key].value =
              filters[key][0]);
          this.state.popup[key] &&
            (this.state.popup[key].valueChoosen = filters[key][0]);
        });
      this.setState({
        filterData: nwfilterData,
        numberPopup: nwnumberQuantityPopup,
      });
    }
   
  }
  // static getDerivedStateFromProps(props, state) {
  //   // Any time the current user changes,
  //   // Reset any parts of state that are tied to that user.
  //   // In this simple example, that's just the email.
  //   if (props.filterData) {
  //     debugger
  //     console.log("dddddd");
  //     const nwfilterData = { ...state.filterData };
  //     const { date, filters } = props.filterData;
      
  //     const nwnumberQuantityPopup = {
  //       ...state.numberPopup,
  //     };
  //    date &&
  //       (nwfilterData.date =date);
  //       filters && Object.keys(filters).forEach((key) => {
  //         nwfilterData.filters[key] = [...filters[key]];
  //         nwnumberQuantityPopup.numberQuantityPopup.list[key] &&
  //           (nwnumberQuantityPopup.numberQuantityPopup.list[key].value =
  //             filters[key][0]);
  //         state.popup[key] &&
  //           (state.popup[key].valueChoosen = filters[key][0]);
  //       });
      
  //     return {
  //       filterData: nwfilterData,
  //       numberPopup: nwnumberQuantityPopup,
  //     }
  //   }
  //   return null;
  // }
  componentWillUnmount() {
    this._isMounted = false;
  }


  redirectOnSubmit = (e) => {
    
    this.setState({
      searchSubmitted: true,
    });
  };
  render() {
    let s = this.state;
    if (this.state.searchSubmitted) {
      return (
        <Redirect
          to={{
            pathname: "/events/shop",
            state: { filterData: this.state.filterData },
          }}
        />
      );
    }
    return (
      <form action="" onSubmit={this.submitForm} className="booking-form">
        <div className="row  align-center flex-wrap animate">
          {!this.props.sideForm && (
            <div
              className={`col  ${
                this.props.sideForm ? "col_full" : "col-4 col__border"
              } `}
            >
              <RenderFeild
                label="اختار الصنف"
                value={this.state.popup.categoryName.valueChoosen}
                type="category"
                togglePopup={() => {
                  this.togglePopup(this.state.popup.categoryName);
                }}
                themeColorClass={this.props.globalProps.themeColorClass}
                catRef={this.catRef}
                icon={IoIosAddCircle}
                renderPopup={() =>
                  this.renderPopup(
                    this.state.popup.categoryName,
                    this.state.popup.categoryName.name
                  )
                }
              />
            </div>
          )}{" "}
          <div
            className={`col  ${
              this.props.sideForm ? "col_full" : "col-4 col__border"
            } `}
          >
            <RenderFeild
              label="اختار التاريخ"
              value={this.state.filterData.date}
              type="date"
              togglePopup={() => {
                this.toggleDate(true);
              }}
              themeColorClass={this.props.globalProps.themeColorClass}
              icon={IoIosCalendar}
              renderPopup={() => this.renderDatePopup(this.state.datePopup)}
            />
          </div>
          <div
            className={`col ${
              this.props.sideForm ? "col_full" : "col-4"
            } row align-center flex-wrap justify-between`}
          >
            <RenderFeild
              label="العدد"
              values={this.state.numberPopup.numberQuantityPopup.list}
              type="category"
              togglePopup={() =>
                this.toggleNumberPopupOpen(
                  this.state.numberPopup.numberQuantityPopup
                )
              }
              themeColorClass={this.props.globalProps.themeColorClass}
              catRef={this.catRef}
              icon={IoIosAlbums}
              renderPopup={() =>
                this.renderPopupNumber(
                  this.state.numberPopup.numberQuantityPopup
                )
              }
              className={`col ${
                this.props.sideForm ? "col_full no-padding-right" : "col-6"
              } col__no-padding-right`}
            />
            {!this.props.bookForm ? (
              <MyButton
                value="بحث"
                buttonType="bold"
                onClick={
                  this.props.sideForm
                    ? (e) => this.props.onClick(e, this.state.filterData)
                    : this.submitForm
                }
                utilClass={` cta-btn--large col-6 w-100`}
                linkClasses={`col ${
                  this.props.sideForm ? "col_full no-padding-right" : "col-6"
                } col__no-padding-right`}
              />
            ) : (
              <MyButton
                value={this.props.isPending ? "التحقق..." : "عربن"}
                buttonType="bold"
                onClick={(e) => this.props.onClick(e, this.state.filterData)}
                utilClass={` cta-btn--large col ${
                  this.props.sideForm ? "col_full " : "col-6"
                } col__no-padding-right w-100`}
                disabled={this.props.isPending}
              />
            )}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  //EventsCateogries:state.eventsCategories
});

export default connect(mapStateToProps)(EventsForm);
