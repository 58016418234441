import React from "react";
import DetailRight from "./DetailRight";
import DetailLeft from "./DetailLeft";
import EventsForm from "../../EventsPage/EventsForm";
import Modal from "../Modal/Modal";
import { connect } from "react-redux";
import MyButton from "../MyButton";
import { setOverlay } from "../../../actions";

function DetailBox(props) {
  const [open, setOpen] = React.useState(false);
  // const {productDetail} = props;
  const renderOverlay = (data) => {
     
   props.setOverlay(data);
  };
 
  return (
    <div className="productDetail">
      <div className="row flex-wrap">
        <div className="col col-4">
        {  <DetailRight
            price={props.hotel?props.productDetail.lowest :props.productDetail.price}
            orboon={props.hotel?props.productDetail.lowestOrboon :props.productDetail.orboon.priceToPay}
            company={props.hotel? props.productDetail: props.productDetail.company}
            BookingComponent={props.RightForm}
            title="مربع الحجز"
            globalProps={props.globalProps}
            renderOverlay={renderOverlay}
            onFormQuery={props.onFormQuery}
            filterData={props.filterData}
            formError={props.formError}
            errorMessage={props.errorMessage}
            isPending={props.isPending}
            hotel={props.hotel}
            clinic={props.clinic}
            CustomComponent={props.CustomComponent}
            type={props.type}
        id={props.productDetail._id}

          />}
        </div>
        <div className="col col-8">
        <DetailLeft
          
            {...props}
          />
            
        </div>
      </div>
      <div className="detailBottom container w-100">
        <MyButton
          value="عربن"
          buttonType="bold"
          onClick={() => setOpen(true)}
          utilClass={` cta-btn--large col col-6 `}
        />
        <h6 className=" col col-6">
          {props.hotel?props.productDetail.lowestOrboon :props.productDetail.orboon.priceToPay} ريال
        </h6>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DetailRight
        id={props.productDetail._id}
              price={props.hotel?props.productDetail.lowest :props.productDetail.price}
              orboon={props.hotel?props.productDetail.lowestOrboon :props.productDetail.orboon.priceToPay}
              company={props.hotel? props.productDetail: props.productDetail.company}
              BookingComponent={props.RightForm}
              title="مربع الحجز"
              globalProps={props.globalProps}
              renderOverlay={renderOverlay}
              onFormQuery={props.onFormQuery}
              filterData={props.filterData}
              formError={props.formError}
              errorMessage={props.errorMessage}
              isPending={props.isPending}
              hotel={props.hotel}
            clinic={props.clinic}
            type={props.type}


        />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
});

const mapDispatchToProps = {setOverlay};

export default connect(mapStateToProps, mapDispatchToProps)(DetailBox);
