import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import FormFeild from "../FormHelpers/FormFeild";
import MyButton from "../../MyButton";
import Form from "../Form";
import { loginAction, resetErrors } from "../../../../actions";

class LoginForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      formError: false,
      formErrorMessage: "",
      formSuccess: false,
      formData: {
        email: {
          element: "input",
          value: "",
          config: {
            name: "email",
            type: "email",
            placeholder: "ايميلك*",
          },
          validation: {
            required: true,
            email: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        password: {
          element: "input",
          value: "",
          config: {
            name: "password",
            type: "password",
            placeholder: "كلمة السر*",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
      },
    };
  }
  emptyErrors = () => {
    //when input change
    const { errors } = this.props.auth;
    if (errors && errors.length > 0) {
      this.props.resetErrors();
    }
  };
  submitAction = (data) => {
    
    this.props.loginAction(data).then(e=>{

    }).catch(err=>{

    })
  };
  renderFormFeilds = () => {
    const feilds = Object.keys(this.state.formData);

    return feilds.map((feild, i) => (
      <FormFeild
        id={feild}
        key={i}
        formData={this.state.formData[feild]}
        change={(element) => this.updateForm(element)}
        isError={this.state.formError}
      />
    ));
  };
  render() {
    const { errors, isAuth, isLoading } = this.props.auth;

    // if (isAuth) {
    //   
    //   const { state } = {...this.props.location}
    //   return state ? (
    //     <Redirect
    //       to={{
    //         pathname: state.from.pathname,
    //         state: { ...state.from.state },
    //       }}
    //     />
    //   ) : (
    //     <Redirect
    //       to={{
    //         pathname: "/",
    //       }}
    //     />
    //   );
    // }
    if (errors.length > 0) {
      
      toast.error(errors[0].message || "خطأ غير معروف");
    }
    return (
      <form className="form" onSubmit={this.submitForm} action="">
        {this.renderFormFeilds()}

        <MyButton
          value={isLoading ? "سجل..." : "سجل"}
          buttonType="bold"
          utilClass={` cta-btn--small`}
          onClick={this.submitForm}
          disabled={isLoading}
        />
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, { loginAction, resetErrors })(
  LoginForm
);
