import React from "react";
import { MdFavorite } from "react-icons/md";

export default function Likebutton({ liked, onClick, globalTheme }) {
  return (
    <div className="like-wrapper">
      <i
        onClick={onClick}
        className={`heart ${liked ? "press" : ""} ${globalTheme.themeClass}`}
      >
        {" "}
        <MdFavorite className="icon__inside" size="25px" />{" "}
      </i>
      <span className={`like-text ${liked ? "press" : ""}`}>أعجبك</span>
    </div>
  );
}

