import React, { Component } from "react";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

import MenuDropDown from "../MenuDropDown";
import { returnName } from "../../../helpers";
import NumMenuDropDown from "../NumMenuDropDown";
import DateDropDown from "../DateComponents/DateDropDown";

export default class BookingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popup: {},
      numberPopup: {},
      datePopup: {},
      labelValue: {},
      popupOpen: false,
      datePopupValues: {},
      filterData: {},
      searchSubmitted: {},
    };
  }

  setPopupState = (res) => {
    console.log(res);

    let popup = { ...this.state.popup };

    res &&
      Object.keys(res).forEach((key, i) => {
        popup[key] = { ...popup[key], List: res[key] };
      });

    this.setState({
      popup,
    });
  };
  togglePopup = (e) => {
    const popup = { ...this.state.popup };
    popup[e.name].open = !this.state.popup[e.name].open;
    this.setState({
      popup,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.redirectOnSubmit(e);
  };
  setLabelValue = (elemen, stateFilterProperty) => {
    let popup = { ...this.state.popup };
    popup[stateFilterProperty].valueChoosen = returnName(elemen);
    //  popup[stateFilterProperty].open= false;
    this.catRef.current.value = returnName(elemen);
    const newFilterData = { ...this.state.filterData };
    newFilterData.filters[stateFilterProperty] &&
      (newFilterData.filters[stateFilterProperty] = [elemen._id]);
    this.setState({
      popup,

      filterData: newFilterData,
    });
  };
  renderPopup = (array, stateFilterProperty) => {
    const { themeClass, themeColorClass } = this.props;
    return (
      this.state.popup[array.name].open && (
        <MenuDropDown
          // themeClass={themeClass}
          // themeColorClass={themeColorClass}
          //menuRef={this.menuRef}
          array={array}
          togglePopup={() => this.togglePopup(array)}
          open={this.state.popup[array.name].open}
          onClick={(element) =>
            this.setLabelValue(element, array.name)
          }
        />
      )
    );
  };
  toggleNumberPopupOpen = (element) => {
    const { numberPopup } = { ...this.state };
    if (!numberPopup[element.name].open) {
      numberPopup[element.name].open = true;
      this.setState({
        numberPopup,
      });
    }
  };
  toggleNumberPopupClose = (element) => {
    const { numberPopup } = { ...this.state };
    numberPopup[element.name].open = false;
    this.setState({
      numberPopup,
    });
  };
  onNumChange = (event, element, key) => {
    const { numberPopup } = { ...this.state };
    numberPopup[element.name].list[key].value = event.target.value;
    this.setState({
      numberPopup,
    });
  };
  addValue = (element, key) => {
    const newFilterData = { ...this.state.filterData };
    const { numberPopup } = { ...this.state };
    if (numberPopup[element.name].list[key].value < 10) {
      numberPopup[element.name].list[key].value =
        numberPopup[element.name].list[key].value + 1;

      newFilterData.filters[key] &&
        (newFilterData.filters[key] = [
          numberPopup[element.name].list[key].value,
        ]);

      this.setState({
        numberPopup,
      });
    }
  };
  minValue = (element, key) => {
    const newFilterData = { ...this.state.filterData };
    const { numberPopup } = { ...this.state };
    if (numberPopup[element.name].list[key].value > 1) {
      numberPopup[element.name].list[key].value =
        numberPopup[element.name].list[key].value - 1;
      newFilterData.filters[key] &&
        (newFilterData.filters[key] = [
          numberPopup[element.name].list[key].value,
        ]);
      this.setState({
        numberPopup,
      });
    }
  };

  renderPopupNumber = (elemntToRender) => {
    return elemntToRender.open ? (
      <NumMenuDropDown
        elemntToRender={elemntToRender}
        onChange={this.onNumChange}
        addValu={this.addValue}
        minValu={this.minValue}
        IoIosRemove={IoIosRemove}
        IoIosAdd={IoIosAdd}
        toggleNumberPopupClose={() => {
          this.toggleNumberPopupClose(elemntToRender);
        }}
      />
    ) : null;
  };

  onDateChange = (range) => {
    const newFilterData = { ...this.state.filterData };

    newFilterData.date = range;

    this.setState({
      filterData: newFilterData,
    });
  };
  toggleDate = (value) => {
    if (value !== this.state.popup.open) {
      this.setState({
        datePopup: { ...this.state.datePopup, open: value },
      });
    }
  };
  renderDatePopup = (datePopup,clinic) => {
    return datePopup.open ? (
      <DateDropDown
        onDateChange={this.onDateChange}
        toggleDateClose={() => this.toggleDate(false)}
        clinic={clinic}
      />
    ) : null;
  };
}
