import React from "react";
import moment from "moment";
import MyButton from "../../shared/MyButton";
import "moment/locale/ar";
export default function Invoice({bookingDetail}) {
  moment.locale("ar");
  
  return (
    <div className="invoice-box rtl">
      <table cellPadding={0} cellSpacing={0}>
        <tbody>
          <tr className="top">
            <td colSpan={2}>
              <table>
                <tbody>
                  <tr>
                    <td className="title">
                      <img
                        src="https://www.sparksuite.com/images/logo.png"
                        alt="logo in invoice"
                        style={{ width: "100%", maxWidth: "300px" }}
                      />
                    </td>
                    <td>
                    رقم العقد : #{bookingDetail.no}
                      <br /> <br />
                     انشئ:  {moment(bookingDetail.createdAt).format("dddd")}{" "}
          {moment(bookingDetail.createdAt).format("LL")}
          <br /> <br />
                      بداية:  {moment(bookingDetail.startAt).format("dddd")}{" "}
          {moment(bookingDetail.startAt).format("LL")}
          <br /> <br />
                      نهاية:  {moment(bookingDetail.endAt).format("dddd")}{" "}
          {moment(bookingDetail.endAt).format("LL")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="information">
            <td colSpan={2}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      {bookingDetail.customer.name}
                      <br /> <br />
                      {bookingDetail.customer.phones[0]}  
                      <br /> <br />
                      {bookingDetail.customer.phones[1]}

               
                    </td>
                    <td>
                      شركة : {bookingDetail.product.company.companyName}
                      <br />
                      <br />
                      {bookingDetail.product.company.address.city}, 
                      {bookingDetail.product.company.address.street}

                      <br />
                      <br />
                      {bookingDetail.product.company.phones[0]} - 
                      {bookingDetail.product.company.phones[1]} 
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="heading">
            <td>عملية الدفع</td>
            <td>رقم الحوالة</td>
          </tr>
          <tr className="details">
            <td>حوالة امتياز</td>
  <td>{bookingDetail.transferNumber}</td>
          </tr>
          <tr className="heading">
            <td>المنتج</td>
            <td>السعر الكامل</td>
          </tr>
          <tr className="item last">
            <td><MyButton
                value={bookingDetail.product.name}
                linkTo={`/hotels/productDetails/${bookingDetail.product._id}`}
                linkButton={true}
                linkClasses={"link"} />
            <p> <span>رقم الغرفة : {bookingDetail.product.room_Num}</span> &diams;
       </p>
            </td>
            <td>{bookingDetail.totalPrice} ريال</td>
          </tr>
      
          <tr className="total">
            <td />
            <td>الواصل (العربون): {bookingDetail.paid} ريال</td>
          </tr>
          <tr className="total">
            <td />
            <td>المتبقي: {bookingDetail.totalPrice - bookingDetail.paid} ريال</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
