import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getProductByID,
  resetErrors,
  getProductsToShop,
  clearProductById,
  clearShopProducts,
  getVisitorsInfo,

  visitProduct
} from "../../../actions";
import { toast } from "react-toastify";
import DetailBox from "../../shared/productDetail/DetailBox";

import ResError from "../../shared/ResError";

import ClinicsCustomComponent from "./ClinicsCustomComponent";
import ClinicsForm from "../ClinicsForm";

class ClinicsProductDetailsIndex extends Component {
  constructor() {
    super();
    this.state = {
      success: false,
      formError: false,
      errorMessage: "",
      isPending: false,
      filterData: {
        filters: {
          categoryName: [],
          price: [],

          address: [],
        },
        date: "",
      },
    };
  }
  componentDidMount() {
    const id = this.props.match.params.id;

    const { state } = this.props.location;
    this._isMounted = true;
    if (state) {
      this.setState(
        {
          filterData: { ...state.filterData },
        },
        () => {
          this._isMounted && this.props.getProductByID(id, "clinicsProducts");
          this._isMounted &&   this.onFormQuery2(null, this.state.filterData);
        }
      );
    } else {
      this._isMounted && this.props.getProductByID(id, "clinicsProducts");
      this._isMounted &&  this.onFormQuery2(null, this.state.filterData);
    }
    
    getVisitorsInfo().then(res=>{
     
     localStorage.setItem(`clinics-${id}`,'visited');                
     visitProduct(id,'clinics',res.ip).then(res=>{
      console.log(res)
     }).catch(err=>console.log(err))
    console.log(res);
   }).catch(err=> console.log(err))
  }

  componentWillUnmount() {
    this._isMounted = false;
     this.props.clearProductById();
     this.props.clearShopProducts();
  }
  onFormQuery = (e, formData) => {
    e.preventDefault();
    console.log(formData);

    if (
      formData.date &&
      formData.date.startAt === "" &&
      formData.date.endAt === ""
    ) {
      this.setState({
        formError: true,
        errorMessage: "يجب عليك ان تحدد تاريخاً  ",
        success: false,
        isPending: false,
      });
    } else {
      this.setState({ success: false, formData: false, isPending: true });
      const filterData = { ...formData };
      filterData.filters._id = this.props.match.params.id;
      this.props
        .getProductsToShop(
          "clinicsProducts",
          0,

          100,
          "_id",
          "asc",

          filterData,
          []
        )
        .then((res) => {
          if (res && res.length > 0) {
            res.length === 1 &&
              this.setState(
                {
                  success: true,
                  isPending: false,
                  formError: false,
                  bookingData: {
                    date: filterData.date,
                    id: this.props.match.params.id,
                    product: res[0],
                  },
                },
                () => {
                  toast.success("يتم تحويلك...");
                  setTimeout(() => {
                    this.props.history.push({
                      pathname: "/clinics/confirmBooking",
                      state: { filterData: this.state.bookingData },
                    });
                  }, 2500);
                }
              );

            res.length > 1 &&
              this.setState({
                formError: true,
                errorMessage: "خطأ نادر! حاول اعادة تحميل الصفحة",
                success: false,
                isPending: false,
              });
          } else {
            this.setState({
              formError: true,
              errorMessage: "محجوز في هذا التاريخ",
              success: false,
              isPending: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            formError: true,
            errorMessage: err[0] ? err[0].message : "خطأ أعد المحاولة",
            success: false,
            isPending: false,
          });
        });
    }
  };
  onFormQuery2 = (e, formData) => {
    e && e.preventDefault();
    console.log(formData);
    debugger;

    this.setState(
      {
        filterData: { ...formData },
        success: false,
        formData: false,
        isPending: true,
      },
      () => {
        this.props
          .getProductsToShop(
            "clinicsProducts",
            0,
            100,
            "_id",
            "asc",
            {
              filters: {
                _id: [this.props.match.params.id],
              },
              date: this.state.filterData.date,
            },
            []
          )
          .then((res) => {
            this.setState({
              success: true,
              isPending: false,
              formError: false,
            });
          })
          .catch((err) => {
            this.setState({
              formError: true,
              errorMessage: err[0] ? err[0].message : "خطأ أعد المحاولة",
              success: false,
              isPending: false,
            });
          });
      }
    );
  };
  onTimeClick = (time) => {
    debugger;
    this.props.history.push({
      pathname: "/clinics/confirmBooking",
      state: {
        filterData: {
          date: this.state.filterData.date,
          id: this.props.match.params.id,
          product: this.props.productDetail.productDetail,
          time,
        },
      },
    });
  };
  render() {
    const products = this.props.products;
    const { isLoading, productDetail, errors } = this.props.productDetail;
    const { success, formError, errorMessage, isPending } = this.state;
    // const productDetail = toShopProducts[0] ? toShopProducts[0]:toShopProducts
    return (
      <div>
        <div className=" container">
          {errors && errors.length > 0 && <ResError errors={errors} />}
          {isLoading ? (
            <h1>Loading...</h1>
          ) : productDetail._id ? (
            <div className="productDetail">
              <DetailBox
                clinic
                CustomComponent={
                  <ClinicsCustomComponent
                    products={products} //1 element
                    date={this.state.filterData.date}
                    onTimeClick={this.onTimeClick}
                  />
                }
                RightForm={ClinicsForm}
                onFormQuery={this.onFormQuery2}
                productDetail={productDetail}
                type="clinics"
                filterData={this.state.filterData}
                formError={formError}
                errorMessage={errorMessage}
                isPending={isPending}
              />
            </div>
          ) : (
            <ResError errors={[{ message: "خطأ" }]} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productDetail: state.productDetail,
  products: state.products,
});

const mapDispatchToProps = {
  getProductByID,
  resetErrors,
  getProductsToShop,
  clearProductById,
  clearShopProducts
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicsProductDetailsIndex);
