import React, { Component } from "react";
import { IoIosAddCircle, IoIosCalendar } from "react-icons/io";

import EventsForm from "./EventsForm";
import ServicesLine from "../shared/ServicesLine";
import Trending from "../shared/Trending";

class MainEvents extends Component {
  constructor() {
    super();
    this.state = {
      categories: [
        { _id: "22", name: "hello" },
        { _id: "11", name: "hi" },
      ],
      servicesList: [
        {
          title: "اكثر من 200 شركة",
          desc: "يوجد لدينا ابرز الشركات المتخصصة في الأفراح والمناسبات",
          Icon: IoIosAddCircle,
        },
        {
          title: "اكثر من 200 شركة",
          desc: "يوجد لدينا ابرز الشركات المتخصصة في الأفراح والمناسبات",
          Icon: IoIosCalendar,
        },
        {
          title: "اكثر من 200 شركة",
          desc: "يوجد لدينا ابرز الشركات المتخصصة في الأفراح والمناسبات",
          Icon: IoIosAddCircle,
        },
      ],
      trendingList: [
        {
          title: "من 200 شركة",
          desc: "15 منتج",
          image: 'events',
        },
              {
          title: "اكثر من ",
          desc: "19 منتج",
          image: 'clinics',
        },
        {
          title: "اكثر من شركة",
          desc: "19 منتج",
          image: 'entertainment',
        },
        {
          title: " 200 شركة",
          desc: "19 منتج",
          image: 'hotels',
        },
        {
          title: "اكثر من 200 شركة",
          desc: "19 منتج",
          image: 'clinics',
        },
      ],
    };
  }
  componentDidMount() {}

  render() {
    return (
      <>
        <div
          className={`large-hero ${this.props.small && "large-hero--small"}`}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/events-bg.jpg`}
            alt="خلفية لقسم حجز الحفلات في اليمن"
            className={`large-hero__image`}
          />
          <div className="large-hero__overlay"></div>
          <div className="large-hero__content">
            <div className="wrapper">
              {!this.props.small && (
                <>
                  <h1 className="large-hero__title">ابدأ مناسبتك من هنا</h1>
                  <h2 className="large-hero__subtitle">
                    عربن ما تريد بأقل سعر وأكثر أماناً وسهولة{" "}
                  </h2>
                </>
              )}
              <div className="container container__not-hidden">
                <EventsForm />
              </div>
            </div>
          </div>
        </div>

        <ServicesLine list={this.state.servicesList} />
        <Trending badge="منتج" type='eventsProducts'/>
      </>
    );
  }
}

export default MainEvents;
