import React from "react";
import FormFrame from "../../shared/Form/FormFrame";
import { Link } from "react-router-dom";
import { getAvailableTimes } from "../../../helpers";
import MyButton from "../../shared/MyButton";

export default function TimesBox(props) {
  const [open, setOpen] = React.useState(false);
  debugger
  let availableTimes = props.secondPeriod
    ? [
        ...getAvailableTimes(props.firstPeriod),
        ...getAvailableTimes(props.secondPeriod),
      ]
    : getAvailableTimes(props.firstPeriod);
  const renderTimes = (times) => {
    return (
      <>
        {times.map((time, i) => {
          return props.times.includes(time) ? (
            <input
              key={i}
              type="button"
              value={time}
              onClick={() => props.onTimeClick(time)}
              className=" cta-btn cta-btn--time cta-btn--time--active"
            />
          ) : (
            <input
              type="button"
              key={i}
              value={time}
              onClick={() => props.onTimeClick(time)}
              className=" cta-btn cta-btn--time cta-btn--time--disabled"
              disabled={true}
            />
          );
        })}
        {/* <a onClick={() => setOpen(true)}> more</a> */}
      </>
    );
  };
  return (
    <div className="timeBox">
      <FormFrame
        footerComponent={() => (
          <MyButton
            type="button"
            buttonType="bold"
            value={open ? "أقل" : "المزيد"}
            onClick={() => setOpen(!open)}
            utilClass=" cta-btn--small no-margin w-100"
          />
        )}
        noLine={true}
        style={{ marginBottom: ".5rem", width: "90px" }}
        title={props.title}
        titleClasses="mg-bottom font-Default theme-clinics"
        formClasses="border-none no-margin "
        bodyClasses="no-padding row flexColumn "
      >
        {!open
          ? renderTimes(availableTimes.splice(0, 5))
          : renderTimes(availableTimes)}
      </FormFrame>
    </div>
  );
}
