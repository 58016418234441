import React, { Component } from "react";
import { connect } from "react-redux";

import HotelsForm from "./HotelsForm";
import { IoIosAddCircle, IoIosCalendar, IoIosAlbums } from "react-icons/io";
import ServicesLine from "../shared/ServicesLine";
import Trending from "../shared/Trending";

 class MainHotels extends Component {
  state={
    servicesList: [
      {
        title: "اكثر من 200 شركة",
        desc: "يوجد لدينا ابرز الشركات المتخصصة في الأفراح والمناسبات",
        Icon: IoIosAddCircle,
      },
      {
        title: "اكثر من 200 شركة",
        desc: "يوجد لدينا ابرز الشركات المتخصصة في الأفراح والمناسبات",
        Icon: IoIosCalendar,
      },
      {
        title: "اكثر من 200 شركة",
        desc: "يوجد لدينا ابرز الشركات المتخصصة في الأفراح والمناسبات",
        Icon: IoIosAddCircle,
      },
    ],
   }
  render() {
    return (
      <>
      <div className={`large-hero ${this.props.small&& "large-hero--small"}`}>
        <img
          src={`${process.env.PUBLIC_URL}/images/hotels-bg.jpg`}
          alt="خلفية لقسم حجز الحفلات في اليمن"
          className={`large-hero__image`}
        />
        <div className="large-hero__overlay"></div>
        <div className="large-hero__content">
          <div className="wrapper">
          {!this.props.small &&  <>  <h1 className="large-hero__title">اختار فندقك المثالي</h1>
            <h2 className="large-hero__subtitle">احصل على أفضل العروض من العديد من الفنادق المختلفة</h2>
           </>}
            <div className="container container__not-hidden">
            <HotelsForm/>
            </div>
          </div>
        </div>
      </div>
      <ServicesLine list={this.state.servicesList} />
      <Trending badge="اماكن" type='hotelsProducts'/>

</>
    );
  }
}



export default MainHotels;

