import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import FormFeild from "../FormHelpers/FormFeild";
import MyButton from "../../MyButton";
import Form from "../Form";
import {
  registerAction,
  resetErrors,
  getCustomer,
  editCustomer,
} from "../../../../actions";

class RegisterForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      formError: false,
      formErrorMessage: "",
      formSuccess: false,
      editLoading: false,
      formData: {
        name: {
          element: "input",
          value: "",
          config: {
            name: "name",
            type: "text",
            placeholder: "اسمك*",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        username: {
          element: "input",
          value: "",
          config: {
            name: "username",
            type: "text",
            placeholder: "اسم المستخدم*",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        phone1: {
          element: "input",
          value: "",
          config: {
            name: "phone1",
            type: "text",
            placeholder: "رقم تلفونك*",
          },
          validation: {
            required: true,
            phone: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        phone2: {
          element: "input",
          value: "",
          config: {
            name: "phone2",
            type: "text",
            placeholder: "رقم تلفون آخر (اختياري)",
          },
          validation: {
            phone: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        city: {
          element: "input",
          value: "",
          config: {
            name: "city",
            type: "text",
            placeholder: "عنوانك (المدينة)*",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        street: {
          element: "input",
          value: "",
          config: {
            name: "street",
            type: "text",
            placeholder: "عنوانك (الشارع)*",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        email: {
          element: "input",
          value: "",
          config: {
            name: "email",
            type: "email",
            placeholder: "ايميلك*",
          },
          validation: {
            required: true,
            email: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        password: {
          element: "input",
          value: "",
          config: {
            name: "password",
            type: "password",
            placeholder: "كلمة السر*",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        confirmPassword: {
          element: "input",
          value: "",
          config: {
            name: "confirmPassword",
            type: "password",
            placeholder: "تأكيد كلمة السر*",
          },
          validation: {
            required: true,
            confirm: "password",
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
      },
    };
  }
  componentDidMount() {
    console.log(this.props.edit);
    if (this.props.edit) {
      getCustomer(this.props.auth.username._id)
        .then((res) => {
          debugger;
          const nwFormData = { ...this.state.formData };
          Object.keys(nwFormData).forEach((key, i) => {
            if (
              key !== "password" &&
              key !== "phone1" &&
              key !== "street" &&
              key !== "city"
            ) {
              if (nwFormData[key]) {
                nwFormData[key].value = res[key];
              }
            } else if (key === "phone1") {
              nwFormData["phone1"].value = res["phones"][0];
              nwFormData["phone2"].value = res["phones"][1];
            } else if (key === "city") {
              nwFormData["city"].value = res["address"].city;
              nwFormData["street"].value = res["address"].street;
            }
          });

          this.setState({
            formData: nwFormData,
            create: false,
          });
        })
        .catch((err) => {
          this.setState({
            create: false,
            formError: true,
            formErrorMessage: err[0]
              ? err[0].message
              : "خطأ    في جلب تفاصيل المستخدم",
          });
        });
    }
  }
  emptyErrors = () => {
    //when input change
    const { errors } = this.props.auth;
    if (errors && errors.length > 0) {
      this.props.resetErrors();
    }
  };
  submitAction = (data) => {
    if (this.props.edit) {
      this.setState({ editLoading: true });
      editCustomer(this.props.auth.username._id, data)
        .then((e) => {
          this.setState({ editLoading: false,formError:false })
          this.props.history.push('/')
        })
        .catch((e) => {
          this.setState({
            editLoading: false,
            formError:true,
            formErrorMessage: (e[0]&&e[0].message) || "خطأ",
          });
        });
    } else {
      this.props.registerAction(data);
    }
  };
  renderFormFeilds = () => {
    const feilds = Object.keys(this.state.formData);

    return feilds.map((feild, i) => (
      <FormFeild
        id={feild}
        key={i}
        formData={this.state.formData[feild]}
        change={(element) => this.updateForm(element)}
        isError={this.state.formError}
      />
    ));
  };
  render() {
    const { errors, isAuth, isLoading } = this.props.auth;

    //  if (isAuth) {

    //         return <Redirect to={{ pathname: "/" }} />;

    //  }
    if (errors.length > 0) {
      toast.error(errors[0].message || "خطأ غير معروف");
    }
    if (this.state.formError) {
      toast.error(this.state.formErrorMessage);
    }
    return (
      <form className="form" onSubmit={this.submitForm} action="">
        {this.renderFormFeilds()}

        <MyButton
          value={this.props.edit ?this.state.editLoading ? "سجل..." : "عدل" : isLoading ? "عدل..." : "سجل"}
          buttonType="bold"
          utilClass={` cta-btn--small`}
          onClick={this.submitForm}
          disabled={isLoading ||this.state.editLoading}
        />
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, { registerAction, resetErrors })(
  withRouter(RegisterForm)
);
