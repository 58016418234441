import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getHotelProductsByCompanyID,
  resetErrors,
  getProductsToShop,
  clearProductById,
} from "../../../../actions";
import { toast } from "react-toastify";
import DetailBox from "../../../shared/productDetail/DetailBox";

import ResError from "../../../shared/ResError";

import HotelsCustomComponent from "../HotelsCustomComponent";
import HotelsForm from "../../HotelsForm";
import DetailLeft from "../../../shared/productDetail/DetailLeft";
import DetailRight from "../../../shared/productDetail/DetailRight";
import HotelCompanyCustomComponent from "./HotelCompanyCustomComponent";

class HotelsCompanyDetails extends Component {
  constructor() {
    super();
    this.state = {
      success: false,
      formError: false,
      errorMessage: "",
      isPending: false,
      filterData: {
        filters: {
          categoryName: [],
          price: [],

          address: [],
          adults: [1],
          children: [1],
          beds: [1],
        },
        date: { startAt: "", endAt: "" },
      },
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    const { state } = this.props.location;
    this._isMounted = true;
    if (state) {
      this.setState(
        {
          filterData: { ...state.filterData },
        },
        () => {
          this._isMounted &&
            this.props.getHotelProductsByCompanyID(
              id,
              "hotelsProducts/getProductByCompanyId",
              this.state.filterData
            );
        }
      );
    } else {
      this._isMounted &&
        this.props.getHotelProductsByCompanyID(
          id,
          "hotelsProducts/getProductByCompanyId",
          { filters: {} }
        );
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.props.clearProductById();
  }

  onFormQuery = (e, formData) => {
    e.preventDefault();
    console.log(formData);

    this.setState();
    const filterData = { ...formData };

    this.setState(
      {
        filterData: { ...formData },
        success: false,
        formData: false,
        isPending: true,
      },
      () => {
        this.props
          .getHotelProductsByCompanyID(
            this.props.match.params.id,
            "hotelsProducts/getProductByCompanyId",
            this.state.filterData
          )
          .then((res) => {
            this.setState({
              success: true,
              isPending: false,
              formError: false,
            });
          })
          .catch((err) => {
            this.setState({
              formError: true,
              errorMessage: err[0] ? err[0].message : "خطأ أعد المحاولة",
              success: false,
              isPending: false,
            });
          });
      }
    );
  };

  render() {
    const {
      isLoading,
      companyDetail: productDetail,
      errors,
    } = this.props.productCompanyDetail;
    const { success, formError, errorMessage, isPending } = this.state;

    return (
      <div>
        <div className=" container">
          {errors && errors.length>0 && <ResError errors={errors} />}
          <HotelsForm
            bookForm
            noCategory
            onClick={this.onFormQuery}
            isPending={isPending}
            filterData={
              this.props.location.state && {
                ...this.props.location.state.filterData,
              }
            }
          />
          {isLoading ? (
            <h1>Loading...</h1>
          ) : productDetail._id ? (
            <div className="productDetail mg-top">
              <DetailBox
                CustomComponent={HotelCompanyCustomComponent}
                RightForm={HotelsForm} //does not appear
                onFormQuery={this.onFormQuery}
                productDetail={productDetail}
                filterData={
                  //this to pass it to the custom cuz we donot have form side here, other feild pass it to the forms
                 this.state.filterData
                }
                type="hotels"
                formError={formError}
                errorMessage={errorMessage}
                isPending={isPending}
                hotel={true}
              />
            </div>
          ) : (
            <ResError errors={[{ message: "خطأ" }]} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productCompanyDetail: state.productCompanyDetail,
});

const mapDispatchToProps = {
  getHotelProductsByCompanyID,
  resetErrors,
  getProductsToShop,
  clearProductById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelsCompanyDetails);
