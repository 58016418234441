import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function MyButton(props) {
  
  const fullClassesAdded =
    props.buttonType === "bold"
      ? `${props.buttonType} 
         
        ${props.utilClass}  
        
        ${props.globalProps.themeClass}`
      : props.buttonType==="light"?props.buttonType +
        " " +
        props.utilClass +
        " " +"cta-btn--light-"+
        props.globalProps.themeClass:"";
  return props.linkClasses ? (
    !props.linkButton ? (
      <Link className={props.linkClasses} to={props.linkTo ? props.linkTo : ""}>
        <input
          type="button"
          className={`btn cta-btn cta-btn--${fullClassesAdded} `}
          onClick={props.onClick}
          value={props.value}
          disabled={props.disabled}
        />
      </Link>
    ) : (
      <Link
        className={`link--hover ${props.globalProps.themeColorClass}`}
        to={props.linkTo ? props.linkTo : ""}
      >
        {props.value}
      </Link>
    )
  ) : (
    <input
      type="button"
      className={`btn cta-btn cta-btn--${fullClassesAdded} `}
      onClick={props.onClick}
      value={props.value}
      disabled={props.disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  //EventsCateogries:state.eventsCategories
});

export default connect(mapStateToProps)(MyButton);
