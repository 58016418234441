import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import MyButton from "../MyButton";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosContacts,
  IoIosSettings,
} from "react-icons/io";
import { MdHistory, MdPerson } from "react-icons/md";
import MenuDropDown from "../MenuDropDown";

export class Header extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      userLoggedPopupOpen: false,
      menuDropdownElements: [
        { name: "من نحن", linkTo: "/", Component: IoIosContacts },
        {
          name: "تواصل بنا",
          linkTo: "https:://www.google.com",
          Component: IoIosContacts,
        },
        { name: "تصفح ", linkTo: "/hotels", Component: IoIosContacts },
      ],
      userLoggedList: {
        List: [
          {
            name: "بروفايلي",
            linkTo: "/general/user/profile",
            Component: IoIosSettings,
          },
          {
            name: "حجوزاتي",
            linkTo: "/general/user/history",
            Component: MdHistory,
          },
          {
            name: "خروج",
            linkTo: "/general/logout",
            Component: MdPerson,
          }, 
        ],
      },
    };
  }
  toggleArrow = () => {
    this.setState({
      popupOpen: !this.state.popupOpen,
    });
  };
  renderPopup = () => {
    const { themeClass, themeColorClass } = this.props.globalProps;
    return (
      <ul
        className={`menu-dropdown ${
          this.state.popupOpen && "menu-dropdown__open"
        }`}
      >
        {this.state.menuDropdownElements.map((elemen, i) => (
          <li
            key={i}
            id="menu-item-8047"
            className={`menu-dropdown__item -${themeClass}`}
          >
            <elemen.Component
              size="30px"
              className={`icon menu-dropdown__item--icon `}
            />
            <Link className="" to={elemen.linkTo}>
              {elemen.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  };
  renderNavTabs = (themeClass) => (
    <div className="navTabs">
      <div className="container ">
        <ul className="navTabs__ul">
          <li>
            <Link
              className={`link -${themeClass} ${
                themeClass === "theme-events" && "active"
              }`}
              to="/events"
            >
              احتفالات
            </Link>
          </li>
          <li>
            <Link
              className={`link -${themeClass} ${
                themeClass === "theme-entertainment" && "active"
              }`}
              to="/entertainment"
            >
              ترفيه
            </Link>
          </li>
          <li>
            <Link
              className={`link -${themeClass} ${
                themeClass === "theme-hotels" && "active"
              }`}
              to="/hotels"
            >
              فنادق
            </Link>
          </li>
          <li>
            <Link
              className={`link -${themeClass} ${
                themeClass === "theme-clinics" && "active"
              }`}
              to="/clinics"
            >
              عيادات
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
  toggleUserPopup = () => {
    this.setState({
      userLoggedPopupOpen: !this.state.userLoggedPopupOpen,
    });
  };
  onUserSettingsClick = (element) => {
    this.toggleUserPopup();
    this.props.history.push(`${element.linkTo}`);
  };
  render() {
    const themeClass = this.props.globalProps.themeClass;
    const { isAuth, username } = this.props.auth;
    const { popupOpen, userLoggedList, userLoggedPopupOpen } = this.state;
    const arrowStyle = { margin: "0 5px", verticalAlign: "sub" };
    return (
      <header>
        <nav className={` header`}>
          <div className="container container__not-hidden">
            <div className="header__container">
              <img
                className="header__container--logo"
                src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
                alt=""
              />

              <ul className="header__container--ul row align-center">
                <li
                  className="header__container--ul__li position-relative"
                  onMouseEnter={this.toggleArrow}
                  onMouseLeave={this.toggleArrow}
                >
                  <a
                    className={`header__container--ul__li--link link -${themeClass}`}
                    href="#"
                  >
                    المزيد
                    {popupOpen ? (
                      <IoIosArrowUp style={arrowStyle} />
                    ) : (
                      <IoIosArrowDown style={arrowStyle} />
                    )}
                  </a>
                  {this.renderPopup()}
                </li>
                {!isAuth ? (
                  <>
                    <li>
                      <MyButton
                        value="تسجيل الدخول"
                        buttonType={`light`}
                        utilClass={` cta-btn--small  no-margin-left`}
                        linkClasses={"link"}
                        linkTo={{
                          pathname: "/general/login",
                          state: { from: this.props.location },
                        }}
                      />
                    </li>
                    <li className="hidden-sm">
                      <MyButton
                        value="تسجيل"
                        buttonType="bold"
                        utilClass={` cta-btn--small`}
                        linkClasses={"link"}
                        linkTo={{
                          pathname: "/general/register",
                          state: { from: this.props.location },
                        }}
                      />
                    </li>
                  </>
                ) : (
                  <li className="header__container--ul__li position-relative">
                    <a
                      className={`header__container--ul__li--link link -${themeClass}`}
                      onClick={this.toggleUserPopup}
                    >
                      <IoIosContacts
                        size="25"
                        style={{ marginRight: "10px", verticalAlign: "middle" }}
                      />
                      <span className="font-Default"> {username.username}</span>
                    </a>
                    {userLoggedPopupOpen && (
                      <MenuDropDown
                        array={userLoggedList}
                        togglePopup={() => this.toggleUserPopup()}
                        open={userLoggedPopupOpen}
                        double={true}
                        onClick={this.onUserSettingsClick}
                      />
                    )}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
        {this.renderNavTabs(themeClass)}
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  globalProps: state.globalProps,
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
