import React from 'react'
import routes from '../routes'
import { Route,Switch,Redirect } from 'react-router-dom';
import ProtectedRoute from '../components/Hoc/auth/ProtectedRoute';
import { connect } from 'react-redux';
import { setGlobalProps } from "../actions";


const switchRoutes = (
    <Switch>
      {routes.EventsRoute.map((prop, key) => {
          
        if (!prop.protected && !prop.logged) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }else if(prop.protected){
           return <ProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        }
        return null;
      })}
      <Redirect from="/events" to="/events/home" />
    </Switch>
  );
  class Events extends React.Component {
    componentDidMount() {
      this.props.setGlobalProps({
        themeClass: "theme-events",
        themeColorClass: "theme-events-color",
      });
    }
    render() {
      return <div>{switchRoutes}</div>;
    }
  } 

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setGlobalProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
