import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserHistory } from "../../actions";
import ResError from "../shared/ResError";
import { getArabicType } from "../../helpers";
import { Link } from "react-router-dom";

export class UserBookingsList extends Component {
  componentDidMount() {
    this.props.getUserHistory();
  }

  renderCards = () => {
    const { bookings } = this.props.history;

    return bookings.length > 0
      ? bookings.map((booking, i) => (
          <li
            key={booking.booking.id}
            className={`one ${booking.booking.product.type}`}
          >
            <span className="task-title">#{booking.booking.no}</span>
            <Link
              className={`task-time link link--hover theme-${booking.booking.product.type}-color`}
              to={`/${booking.booking.product.type}/bookingDetail/${booking.booking._id}`}
            >
              عرض الفاتورة
            </Link>
            <span className="task-cat">
              {booking.booking.product.name} .{" "}
              {getArabicType(booking.booking.product.type)}
            </span>
          </li>
        ))
      : "لايوجد";
  };
  render() {
    const { isLoading, bookings, errors } = this.props.history;
    return (
      <>
        {errors && errors.length > 0 && <ResError errors={errors} />}
        {isLoading ? (
          "Loading"
        ) : (
          <div className="bottom-wrapper position-relative ">
            <div className="title">
              <h3>حجوزاتي</h3>
              <small>ماحجزته عبر عربون</small>
            </div>
            <ul className="tasks">{this.renderCards()}</ul>
          </div>
        )}
      </>
    );
  }
}
{
  /* <li className="tow green hang">
<span className="task-title">Team Meeting</span>
<span className="task-time">2pm</span>
<span className="task-cat">Hangouts</span>
<img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/2.jpg" />
<img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/3.jpg" />
<img src="https://raw.githubusercontent.com/arjunamgain/FilterMenu/master/images/profile.jpg" />
</li> */
}

const mapStateToProps = (state) => ({
  history: state.history,
  globalProps: state.globalProps,
});

const mapDispatchToProps = {
  getUserHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBookingsList);
