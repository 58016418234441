import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import WithEventsTheme from "../../Hoc/themes/WithEventsTheme";
import HorizontalCard from "./HorizontalCard";
import SummeryBox from "../../shared/SummeryBox";
import CollapseComponent from "../../shared/Shop/CollapseComponent";
import RenderDateDisplay from "../../shared/RenderDateDisplay";
import TrasferNumber from "../../shared/Form/TrasferNumber";
import Form from "../../shared/Form/Form";
import FormFrame from "../../shared/Form/FormFrame";
import FormFeild from "../../shared/Form/FormHelpers/FormFeild";
import { createBooking, clearBooking } from "../../../actions";
import { toast } from "react-toastify";
import authService from "../../../services/auth-service";
import ResError from "../../shared/ResError";

class ConfirmBooking extends Form {
  constructor() {
    super();
    
    this.state = {
      data: {
        quantity: 0,
        date: {},
        id: "",
        product: {},
        days: 0,
        transferNumber: "",
        eventAddress: {},
        totalPrice: 0,
      },
      formError: false,
      formErrorMessage: "",
      formSuccess: false,
      formData: {
        transferNumber: {
          element: "input",
          value: "",
          config: {
            name: "transferNumber",
            type: "text",
            placeholder: "رقم حوالة الامتياز*",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        city: {
          element: "input",
          value: "",
          config: {
            name: "city",
            type: "text",
            placeholder: "المحافظة  *",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
        street: {
          element: "input",
          value: "",
          config: {
            name: "street",
            type: "text",
            placeholder: "عنوان الشارع",
          },
          validation: {
            required: true,
          },
          valid: true,
          touched: false,
          validationMessage: "",
        },
      },
    };
  }
  generateDays = (date) => {
    let days = 2;
    days = moment(date.endAt).diff(date.startAt, "days") + 1;
    return days;
  };
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.filterData) {
      console.log(this.props.location.state.filterData);
      const nwData = { ...this.state.data };
      Object.keys(this.props.location.state.filterData).map(
        (key, i) => (nwData[key] = this.props.location.state.filterData[key])
      );
      nwData.days = this.generateDays(nwData.date);
      nwData.totalPrice = nwData.days * nwData.quantity * nwData.product.price;
      this.setState({
        data: nwData,
      });
    } else {
      this.props.history.replace({
        pathname: "/events",
      });
    }
  }
  emptyErrors = () => {};
  onInputChange = (e) => {
    console.log(e);
    this.setState({
      data: { ...this.state.data, transferNumber: parseInt(e.value) },
    });
  };
  generateBookingData = () => {
    const nwData = {};
    const stateData = { ...this.state.data };
    Object.keys(stateData).forEach((key) => {
      if (key !== "product" && key !== "id") {
        if (key !== "date") {
          nwData[key] = stateData[key];
        } else {
          nwData["startAt"] = stateData[key].startAt;
          nwData["endAt"] = stateData[key].endAt;
        }
      } else if (key === "product") {
        nwData[key] = { _id: stateData[key]._id };
      }
    });
    console.log(nwData);
    this.props
      .createBooking(nwData, "bookEvent")
      .then((e) => {
        
      })
      .catch((errors) => {
        errors && errors.length > 0 && toast.error(errors[0].message);
      });
  };
  submitAction = (data) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          transferNumber: data.transferNumber,
          eventAddress: data.address,
        },
      },
      () => this.generateBookingData()
    );
  };
  renderFormFeilds = () => {
    const feilds = Object.keys(this.state.formData);

    return feilds.map((feild, i) => (
      <FormFeild
        id={feild}
        key={i}
        formData={this.state.formData[feild]}
        change={(element) => this.updateForm(element)}
        isError={this.state.formError}
      />
    ));
  };
  componentDidUpdate() {
    const { errors, success } = this.props.booking;

    if (success || (errors && errors.length > 0)) {
      this.props.clearBooking();
    }
  }
  render() {
    const { data } = this.state;
    const { bookedId, errors, isLoading, success } = this.props.booking;

    console.log(moment(data.date.startAt).format("LLLL"));

    if (!authService.isAuthenticated()) {
      return (
        <Redirect
          to={{ pathname: "/general/login", state: { from: this.props.location } }}
        />
      );
    }
    if (success && bookedId !== "") {
      return <Redirect
          to={{ pathname: `/events/bookingDetail/${bookedId}`, state: { success: true } }}
        />
    }
    return (
      <div className="container">
        <div className="row flex-wrap align- confirmBooking">
          <div className="col col-7 col-no-sidePadding">
            <RenderDateDisplay date={data.date} />
            <FormFrame
              footerComponent={() => (
                <p>
                  تذكر: انت تدفع العربون فقط، بقية المبلغ يسلم للمزود عند
                  الحضور.
                </p>
              )}
              style={{ marginBottom: "5rem" }}
              title="ادخل بقية البيانات"
              titleClasses="mg-bottom theme-events"
              formClasses="border-none no-margin shadow-third"
            >
              <form
                className="form mg-top"
                onSubmit={this.submitForm}
                action=""
              >
                {this.renderFormFeilds()}
                <p>ادخل العنوان الذي ستقام فيه المناسبة.</p>
              </form>
            </FormFrame>
            <CollapseComponent
              title="تفاصيل الطلب "
              children={true}
              initOpen={true}
            >
              <HorizontalCard
                photos={data.product.photos}
                price={data.product.price}
                quantity={data.quantity}
                days={data.days}
                name={data.product.name}
              />
            </CollapseComponent>
          </div>

          <div className="col col-5 col-no-sidePadding">
            <div
              style={{ marginBottom: "6rem" }}
              className="general-form border-none shadow-third "
            >
              <div className="general-form__title theme-events">الخلاصة</div>
              <div className="general-form__body no-padding">
                <SummeryBox
                  onClick={this.submitForm}
                  size="medium"
                  price={data.product.price * data.quantity * data.days}
                  percentage={
                    data.product.orboon && data.product.orboon.percentage
                  }
                  isLoading={isLoading}
                  orboon={
                    data.product.orboon &&
                    data.product.orboon.priceToPay * data.quantity * data.days
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
});

const mapDispatchToProps = { createBooking, clearBooking };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmBooking);
