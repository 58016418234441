import React, { Component } from "react";

import MainClinics from "./components/ClinicsPage/MainClinics";

import MainEntertainment from "./components/EntertainmentPage/MainEntertainment";

import MainHotels from "./components/HotelsPage/MainHotels";

import NotFound from "./components/shared/NotFound";

import MainEvent from "./components/EventsPage/MainEvents";

import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "./components/Hoc/Layout";
import { test } from "./actions";
import ShopIndex from "./components/EventsPage/Shop/ShopIndex";
import Login from "./components/shared/Form/Login/Login";
import Register from "./components/shared/Form/Register/Register";
import { ToastContainer, toast } from "react-toastify";
import LoggedInRoute from "./components/Hoc/auth/LoggedInRoute";
import ConfirmBooking from "./components/EventsPage/ProductDetail/ConfirmBooking";
import BookingDetail from "./components/EventsPage/ProductDetail/BookingDetail";
import EntertainmentConfirmBooking from "./components/EntertainmentPage/ProductDetail/EntertainmentConfirmBooking";
import EntertainmentProductDetailsIndex from "./components/EntertainmentPage/ProductDetail/EntertainmentProductDetailsIndex";
import EntertainmentBookingDetail from "./components/EntertainmentPage/ProductDetail/EntertainmentBookingDetail";
import HotelsCompanyDetails from "./components/HotelsPage/ProductDetail/CompanyDetails/HotelsCompanyDetails";
import HotelsProductDetailsIndex from "./components/HotelsPage/ProductDetail/HotelsProductDetailsIndex";
import HotelsConfirmBooking from "./components/HotelsPage/ProductDetail/HotelsConfirmBooking";
import HotelsBookingDetail from "./components/HotelsPage/ProductDetail/HotelsBookingDetail";
import ClinicsConfirmBooking from "./components/ClinicsPage/ProductDetail/ClinicsConfirmBooking";
import ClinicsBookingDetail from "./components/ClinicsPage/ProductDetail/ClinicsBookingDetail";
import UserProfile from "./components/Users/UserProfile";
import UserProfileCopy from "./components/Users/UserProfile copy";
import Logout from "./components/shared/Logout";
import EventsCompany from "./components/EventsPage/EventsCompany";
import EntertainemntCompany from "./components/EntertainmentPage/EntertainemntCompany";
import ClinicsCompany from "./components/ClinicsPage/ClinicsCompany";
import HotelsCompany from "./components/HotelsPage/HotelsCompany";
import "react-toastify/dist/ReactToastify.css";
import ProductDetailsIndex from "./components/EventsPage/ProductDetail/ProductDetailsIndex";
import ProtectedRoute from "./components/Hoc/auth/ProtectedRoute";
import EntertainmentShopIndex from "./components/EntertainmentPage/Shop/EntertainmentShopIndex";
import HotelsShopIndex from "./components/HotelsPage/Shop/HotelsShopIndex";
import ClinicsShopIndex from "./components/ClinicsPage/Shop/ClinicsShopIndex";
import ClinicsProductDetailsIndex from "./components/ClinicsPage/ProductDetail/ClinicsProductDetailsIndex";
const EventsRoute = [
  {
    path: "/home",
    protected: false,
    logged: false,
    component: MainEvent,
    layout: "/events",
  },
  {
    path: "/shop",
    protected: false,
    logged: false,
    component: ShopIndex,
    layout: "/events",
  },
  {
    path: "/confirmBooking",
    protected: true,
    logged: false,
    component: ConfirmBooking,
    layout: "/events",
  },
  {
    path: "/productDetails/:id",
    protected: false,
    logged: false,
    component: ProductDetailsIndex,
    layout: "/events",
  },
  {
    path: "/bookingDetail/:id",
    protected: true,
    logged: false,
    component: BookingDetail,
    layout: "/events",
  },
    {
    path: "/company/:id",
    protected: false,
    logged: false,
    component: EventsCompany,
    layout: "/events",
  },
];
const EntertainmentRoute = [
  {
    path: "/home",
    protected: false,
    logged: false,
    component: MainEntertainment,
    layout: "/entertainment",
  },
  {
    path: "/shop",
    protected: false,
    logged: false,
    component: EntertainmentShopIndex,
    layout: "/entertainment",
  },
  {
    path: "/confirmBooking",
    protected: true,
    logged: false,
    component: EntertainmentConfirmBooking,
    layout: "/entertainment",
  },
  {
    path: "/productDetails/:id",
    protected: false,
    logged: false,
    component: EntertainmentProductDetailsIndex,
    layout: "/entertainment",
  },
  {
    path: "/bookingDetail/:id",
    protected: true,
    logged: false,
    component: EntertainmentBookingDetail,
    layout: "/entertainment",
  },
  {
    path: "/company/:id",
    protected: false,
    logged: false,
    component: EntertainemntCompany,
    layout: "/entertainment",
  },
];
const HotelsRoute = [
  {
    path: "/home",
    protected: false,
    logged: false,
    component: MainHotels,
    layout: "/hotels",
  },
  {
    path: "/shop",
    protected: false,
    logged: false,
    component: HotelsShopIndex,
    layout: "/hotels",
  },
  {
    path: "/confirmBooking",
    protected: true,
    logged: false,
    component: HotelsConfirmBooking,
    layout: "/hotels",
  },
  {
    path: "/hotelCompany/productDetails/:id",
    protected: false,
    logged: false,
    component: HotelsCompanyDetails,
    layout: "/hotels",
  }, {
    path: "/productDetails/:id",
    protected: false,
    logged: false,
    component: HotelsProductDetailsIndex,
    layout: "/hotels",
  },
  {
    path: "/bookingDetail/:id",
    protected: true,
    logged: false,
    component: HotelsBookingDetail,
    layout: "/hotels",
  },
    {
      path: "/company/:id",
      protected: false,
      logged: false,
    component: HotelsCompany,
    layout: "/hotels",
  },
];
const ClinicsRoute = [
  {
    path: "/home",
    protected: false,
    logged: false,
    component: MainClinics,
    layout: "/clinics",
  },
  {
    path: "/shop",
    protected: false,
    logged: false,
    component: ClinicsShopIndex,
    layout: "/clinics",
  },
  {
    path: "/confirmBooking",
    protected: true,
    logged: false,
    component: ClinicsConfirmBooking,
    layout: "/clinics",
  },
  {
    path: "/productDetails/:id",
    protected: false,
    logged: false,
    component: ClinicsProductDetailsIndex,
    layout: "/clinics",
  },
  {
    path: "/bookingDetail/:id",
    protected: true,
    logged: false,
    component: ClinicsBookingDetail,
    layout: "/clinics",
  },
    {
      path: "/company/:id",
      protected: false,
      logged: false,
    component: ClinicsCompany,
    layout: "/clinics",
  },
];
const GeneralRoute = [
  {
    path: "/login",
    protected: false,
    logged: true,
    component: Login,
    layout: "/general",
  },
  {
    path: "/logout",
    protected: false,
    
    component: Logout,
    layout: "/general",
  },
  {
    path: "/register",
    protected: false,
    logged: true,
    component: Register,
    layout: "/general",
  },
  {
    path: "/notFound",
    protected: false,
    logged: false,
    component: NotFound,
    layout: "/general",
  },
  {
    path: "/user/profile",
    protected: true,
    logged: false,
    component: UserProfileCopy,
    layout: "/general",
  },
];
export default { EventsRoute, GeneralRoute, EntertainmentRoute,HotelsRoute ,ClinicsRoute};
