

import React from 'react'
import Card from '../cards/Card'

export default function CardHotels(props) {
  return (
   <Card 
   onCardClick={`/${props.type}`}
   id={props._id}
    
    photos={props.photos}
    type={props.type}
  
    name={props.name}
    orboon={props.orboon}
    price={props.price}
   general={true}
  //  hotels={true}
   
   />
  )
}


