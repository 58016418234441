import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getProductByID,
  resetErrors,
  getProductsToShop,
  clearProductById,
  visitProduct,getVisitorsInfo} from "../../../actions";
import { toast } from "react-toastify";
import DetailBox from "../../shared/productDetail/DetailBox";

import ResError from "../../shared/ResError";
import EntertainmentForm from "../EntertainmentForm";
import EntertainmentCustomComponent from "./EntertainmentCustomComponent";

class EntertainmentProductDetailsIndex extends Component {
  constructor() {
    super();
    this.state = {
      success: false,
      formError: false,
      errorMessage: "",
      isPending: false,
    };
  }
  componentDidMount() {
    const id = this.props.match.params.id;

    this.props.getProductByID(id, "entertainmentProducts");
 
    getVisitorsInfo().then(res=>{
     
     localStorage.setItem(`entertainment-${id}`,'visited');                
     visitProduct(id,'Entertainment',res.ip).then(res=>{
      console.log(res)
     }).catch(err=>console.log(err))
    console.log(res);
   }).catch(err=> console.log(err))
  }

  onFormQuery = (e, formData) => {
    e.preventDefault();
    console.log(formData);

    if (
      formData.date &&
      formData.date.startAt === "" &&
      formData.date.endAt === ""
    ) {
      this.setState({
        formError: true,
        errorMessage: "يجب عليك ان تحدد تاريخاً  ",
        success: false,
        isPending: false,
      });
    } else {
      this.setState({ success: false, formData: false, isPending: true });
      const filterData = { ...formData };
      filterData.filters._id = this.props.match.params.id;
      this.props
        .getProductsToShop(
          "EntertainmentProducts",
          0,

          100,
          "_id",
          "asc",

          filterData,
          []
        )
        .then((res) => {
          if (res && res.length > 0) {
            res.length === 1 &&
              this.setState(
                {
                  success: true,
                  isPending: false,
                  formError: false,
                  bookingData: {
                    date: filterData.date,
                    id: this.props.match.params.id,
                    product: res[0],
                  },
                },
                () => {
                  toast.success("يتم تحويلك...");
                  setTimeout(() => {
                    this.props.history.push({
                      pathname: "/entertainment/confirmBooking",
                      state: { filterData: this.state.bookingData },
                    });
                  }, 2500);
                }
              );

            res.length > 1 &&
              this.setState({
                formError: true,
                errorMessage: "خطأ نادر! حاول اعادة تحميل الصفحة",
                success: false,
                isPending: false,
              });
          } else {
            this.setState({
              formError: true,
              errorMessage: "محجوز في هذا التاريخ",
              success: false,
              isPending: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            formError: true,
            errorMessage: err[0] ? err[0].message : "خطأ أعد المحاولة",
            success: false,
            isPending: false,
          });
        });
    }
  };

  render() {
    const { isLoading, productDetail, errors } = this.props.productDetail;
    const { success, formError, errorMessage, isPending } = this.state;

    return (
      <div>
        <div className=" container">
          {errors && errors.length > 0 && <ResError errors={errors} />}
          {isLoading ? (
            <h1>Loading...</h1>
          ) : productDetail._id ? (
            <div className="productDetail">
              <DetailBox
                CustomComponent={EntertainmentCustomComponent}
                RightForm={EntertainmentForm}
                onFormQuery={this.onFormQuery}
                productDetail={productDetail}
                type="entertainment"
                filterData={
                  this.props.location.state && {
                    ...this.props.location.state.filterData,
                  }
                }
                formError={formError}
                errorMessage={errorMessage}
                isPending={isPending}
              />
            </div>
          ) : (
            <ResError errors={[{ message: "خطأ" }]} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productDetail: state.productDetail,
});

const mapDispatchToProps = {
  getProductByID,
  resetErrors,
  getProductsToShop,
  clearProductById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntertainmentProductDetailsIndex);
